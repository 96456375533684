.pricePagegr-image-responsive{
    display:block;
    max-width:100%;
    height:auto
}
.pricePagecontainer{
    margin-right:auto;
    margin-left:auto;
    padding-left:15px;
    padding-right:15px
}
.pricePagecontainerPrice{
    margin-right:auto;
    margin-left:auto;
    padding-left:15px;
    padding-right:15px
}
@media (min-width:768px){
    .pricePagecontainer{
        width:750px
    }
    .pricePagecontainerPrice{
        width:750px
    }
}
@media (min-width:992px){
    .pricePagecontainer{
        width:970px
    }
    .pricePagecontainerPrice{
        width:970px
    }
}
@media (min-width:1200px){
    .pricePagecontainer{
        width:1170px
    }
    .pricePagecontainerPrice{
        width:1170px
    }
}
.pricePagerow{
    margin-left:-15px;
    margin-right:-15px
}
.pricePagecol-md-2,.pricePagecol-md-4,.pricePagecol-md-8,.pricePagecol-sm-6{
    position:relative;
    min-height:1px;
    padding-left:15px;
    padding-right:15px
}
@media (min-width:768px){
    .pricePagecol-sm-6{
        float:left
    }
    
    .pricePagecol-sm-6{
        width:50%
    }
}
@media (min-width:992px){
    .pricePagecol-md-2,.pricePagecol-md-4,.pricePagecol-md-8{
        float:left
    }
    .pricePagecol-md-8{
        width:66.66666666666666%
    }
    
    .pricePagecol-md-4{
        width:33.33333333333333%
    }
    
    .pricePagecol-md-2{
        width:16.666666666666664%
    }
}

.pricePagebtn{
    display:inline-block;
    margin-bottom:0;
    font-weight:400;
    text-align:center;
    vertical-align:middle;
    cursor:pointer;
    background-image:none;
    border:1px solid transparent;
    white-space:nowrap;
    padding:6px 12px;
    font-size:14px;
    line-height:1.428571429;
    border-radius:4px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none
}
.pricePagebtn:focus{
    outline:thin dotted;
    outline:5px auto -webkit-focus-ring-color;
    outline-offset:-2px
}
.pricePagebtn:focus,.pricePagebtn:hover{
    color:#333;
    text-decoration:none
}
.pricePagebtn.active,.pricePagebtn:active{
    outline:0;
    background-image:none;
    -webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow:inset 0 3px 5px rgba(0,0,0,.125)
}
.pricePagedropdown-toggle:focus{
    outline:0
}
.pricePagenav{
    margin-bottom:0;
    padding-left:0;
    list-style:none
}
.pricePagenav>li{
    position:relative;
    display:block
}
.pricePagenav>li>a{
    position:relative;
    display:block;
    padding:10px 15px
}
.pricePagenav>li>a:focus,.pricePagenav>li>a:hover{
    text-decoration:none;
    background-color:#eee
}
.pricePagenav>li>a>img{
    max-width:none
}
.pricePagenav-pills>li{
    float:left
}
.pricePagenav-pills>li>a{
    border-radius:4px
}
.pricePagenav-pills>li+li{
    margin-left:2px
}
.pricePagenav-pills>li.active>a,.pricePagenav-pills>li.active>a:focus,.pricePagenav-pills>li.active>a:hover{
    color:#fff;
    background-color:#428bca
}
.pricePagenavbar{
    position:relative;
    min-height:50px;
    margin-bottom:20px;
    border:1px solid transparent
}
@media (min-width:768px){
    .pricePagenavbar{
        border-radius:4px
    }
}
@media (min-width:768px){
    .pricePagenavbar-header{
        float:left
    }
}
.pricePagenavbar-collapse{
    max-height:340px;
    overflow-x:visible;
    padding-right:15px;
    padding-left:15px;
    border-top:1px solid transparent;
    box-shadow:inset 0 1px 0 rgba(255,255,255,.1);
    -webkit-overflow-scrolling:touch
}
.pricePagenavbar-collapse.in{
    overflow-y:auto
}
@media (min-width:768px){
    .pricePagenavbar-collapse{
        width:auto;
        border-top:0;
        box-shadow:none
    }
    .pricePagenavbar-collapse.in{
        overflow-y:visible
    }
}
.pricePagecontainer>.pricePagenavbar-collapse,.pricePagecontainer>.pricePagenavbar-header{
    margin-right:-15px;
    margin-left:-15px
}

.pricePagecontainerPrice>.pricePagenavbar-collapse,.pricePagecontainerPrice>.pricePagenavbar-header{
    margin-right:-15px;
    margin-left:-15px
}

@media (min-width:768px){
    .pricePagecontainer>.pricePagenavbar-collapse,.pricePagecontainer>.pricePagenavbar-header{
        margin-right:0;
        margin-left:0
    }
    .pricePagecontainerPrice>.pricePagenavbar-collapse,.pricePagecontainerPrice>.pricePagenavbar-header{
        margin-right:0;
        margin-left:0
    }
}
.pricePagenavbar-nav{
    margin:7.5px -15px
}
.pricePagenavbar-nav>li>a{
    padding-top:10px;
    padding-bottom:10px;
    line-height:20px
}
@media (min-width:768px){
    .pricePagenavbar-nav{
        float:left;
        margin:0
    }
    .pricePagenavbar-nav>li{
        float:left
    }
    .pricePagenavbar-nav>li>a{
        padding-top:15px;
        padding-bottom:15px
    }
}

.pricePagebtn{
    position:relative;
    top:-1px
}
.pricePagegr-space-fix:after,.pricePagegr-space-fix:before,.pricePagecontainer:after,.pricePagecontainerPrice:after,.pricePagecontainer:before,.pricePagecontainerPrice:before,.pricePagenav:after,.pricePagenav:before,.pricePagenavbar-collapse:after,.pricePagenavbar-collapse:before,.pricePagenavbar-header:after,.pricePagenavbar-header:before,.pricePagenavbar:after,.pricePagenavbar:before,.pricePagerow:after,.pricePagerow:before{
    content:" ";
    display:table
}
.pricePagegr-space-fix:after,.pricePagecontainer:after,.pricePagecontainerPrice:after,.pricePagenav:after,.pricePagenavbar-collapse:after,.pricePagenavbar-header:after,.pricePagenavbar:after,.pricePagerow:after{
    clear:both
}
.pricePagecenter-block{
    display:block;
    margin-left:auto;
    margin-right:auto
}
.show{
    display:block!important
}
.hidden{
    display:none!important;
    visibility:hidden!important
}
@-ms-viewport{
    width:device-width
}
@font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-Thin.otf") format('truetype');
     font-weight:100;
}

@font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-ExtraLight.otf") format('truetype');
     font-weight:200;
}


 @font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-Light.otf") format('truetype');
     font-weight:300;
}
 @font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-Regular.otf") format('truetype');
     font-weight:400;
}
 @font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-Medium.otf") format('truetype');
     font-weight:500;
}
 @font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-SemiBold.otf") format('truetype');
     font-weight:600;
}
 @font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-Bold.otf") format('truetype');
     font-weight:700;
}
 @font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-ExtraBold.otf") format('truetype');
     font-weight:800;
}
 @font-face {
    font-family:'Monserrat';
     src:url("./fonts/Montserrat-Black.otf") format('truetype');
     font-weight:900;
}
@font-face {
    font-family:'Proxima Nova';
     src:url("./fonts/Proxima-Nova-Thin.ttf") format('truetype');
     font-weight:100;
}
 @font-face {
    font-family:'Proxima Nova';
     src:url("./fonts/Proxima-Nova-Light.otf") format('truetype');
     font-weight:300;
}
 @font-face {
    font-family:'Proxima Nova';
     src:url("./fonts/Proxima-Nova-Regular.ttf") format('truetype');
     font-weight:400;
}
 @font-face {
    font-family:'Proxima Nova';
     src:url("./fonts/Proxima-Nova-Semibold.ttf") format('truetype');
     font-weight:500;
}
 @font-face {
    font-family:'Proxima Nova';
     src:url("./fonts/ProximaNova-Bold.ttf") format('truetype');
     font-weight:600;
}

 .pricePagefont300 {
    font-weight:300;
}
 .pricePagefont400 {
    font-weight:400;
}
 .pricePagefont500 {
    font-weight:500;
}


/* My Blockout


article,details,footer,header,nav,section {
    display:block;
}
 [hidden] {
    display:none;
}
 html {
    font-size:100%;
     -webkit-text-size-adjust:100%;
     -ms-text-size-adjust:100%;
}
 button {
    font-family:'Monserrat', sans-serif;
}
 input, html {
    font-family:'Proxima Nova', sans-serif;
}
 body {
    margin:0;
     font-family:'Proxima Nova', sans-serif;
    ;
}
 a:focus {
    outline:thin dotted;
     text-decoration:none;
}
 a:hover,a:active {
    outline:0;
     text-decoration:none;
}
 b {
    font-weight:600;
}
 p {
    margin:1em 0;
}
 code {
    font-family:monospace,serif;
     _font-family:'courier new', monospace;
     font-size:16px;
}
 sup {
    font-size:75%;
     line-height:0;
     position:relative;
     vertical-align:baseline;
}
 sup {
    top:-0.5em;
}
 ul {
    margin:1em 0;
}
 ul {
    padding:0 0 0 40px;
}
 nav ul {
    list-style:none;
}
 img {
    border:0;
     -ms-interpolation-mode:bicubic;
}
 form {
    margin:0;
}
 button,input,select {
    font-size:100%;
     margin:0;
     vertical-align:baseline;
}
 button,input {
    line-height:normal;
}
 button,input[type="button"],input[type="submit"] {
    cursor:pointer;
     -webkit-appearance:button;
}
 input[type="checkbox"],input[type="radio"] {
    box-sizing:border-box;
     padding:0;
}
 input[type="search"] {
    -webkit-appearance:textfield;
     -moz-box-sizing:content-box;
     -webkit-box-sizing:content-box;
     box-sizing:content-box;
}
 input[type="search"]:-webkit-search-decoration,input[type="search"]:-webkit-search-cancel-button {
    -webkit-appearance:none;
}
 button::-moz-focus-inner,input::-moz-focus-inner {
    border:0;
     padding:0;
}
 table {
    border-collapse:collapse;
     border-spacing:0;
}
 input,select {
    padding-left:20px;
     padding-right:20px;
}
 body, p, a, ul li, ul li li, a.more {
    font-size:14px;
     line-height:24px;
     font-family:'Proxima Nova', sans-serif;
     font-weight:300;
     color:#293443;
}
 p {
    margin:10px auto;
}
 h1, h2, h2 a, h3, h3 a, h4, h4 a, h5, h5 a, h6, h6 a {
    font-family:'Monserrat', sans-serif;
     font-weight:300;
     color:#293443;
}
 h1 {
    font-size:26px;
     line-height:36px;
     margin-top:0;
     margin-bottom:20px;
}
 h1.gr-section-title {
    margin-bottom:0;
     margin-top:0;
     text-align:center;
}
 h1 span {
    color:#ffffff !important;
     font-weight:100;
     display:block;
     margin-top:10px;
}
 h1.s {
    font-weight:100;
}
 h2 {
    font-size:22px;
     line-height:32px;
     margin:10px 0;
}
 h2, h2 a {
    font-size:22px;
     line-height:32px;
}
 h2.gr-section-title {
    margin-bottom:0;
     margin-top:0;
     text-align:center;
}
 h2.pricePagegr-section-title2 {
    margin-top:0;
     text-align:center;
}
 h2.s {
    color:#293443;
}
 h3 {
    margin:10px 0;
}
 h3, h3 a {
    font-size:18px;
     line-height:28px;
}
 h3.s {
    color:#293443;
     font-weight:300;
}
 h4 {
    margin:10px 0;
}
 h4, h4 a {
    font-size:16px;
     line-height:24px;
}
 h4.pricePagegr-section-title2 {
    margin-top:0;
     text-align:center;
}
 h5 {
    font-size:14px;
     line-height:24px;
     margin:10px 0;
}
 h6 {
    font-size:14px;
     line-height:24px;
     font-weight:500;
     margin:10px 0;
}
 a {
    font-size:14px;
     line-height:24px;
     color:#3474ff;
     cursor:pointer;
     outline:none !important;
}
 a:hover, a:focus,a:active {
    text-decoration:none;
     color:#00aeff;
}
 ::-moz-focus-inner {
    border:0;
}
 ul {
    margin-left:0;
     padding-left:30px;
}
 ul li, ul li li {
    list-style-type:none;
     list-style-image:none;
     position:relative;
}


*/


.bodyStyle, .pStyle {
   
     color:#293443;
}

@media (min-width:768px){
    h1.gr-section-title, h2.gr-section-title {
        margin-bottom:15px;
    }
     h2.pricePagegr-section-title2, h3.pricePagegr-section-title2, h4.pricePagegr-section-title2 {
        margin-bottom:15px;
    }
}
 @media (min-width:768px) and (max-width:991px){

    /* My Blockout

    body, p, a, ul li, ul li li, a.more {
        font-size:14px;
         line-height:24px;
    }
     h1, h1.s {
        font-size:30px;
         line-height:40px;
    }
     h2, h2 a {
        font-size:26px;
         line-height:36px;
         color:#293443;
    }
     h3, h3 a {
        font-size:24px;
         line-height:34px;
    }
     h4, h4 a {
        font-size:22px;
         line-height:32px;
    }
     h5, h5 a {
        font-size:18px;
         line-height:28px;
    }
     h6, h6 a {
        font-size:16px;
         line-height:26px;
    }

    */
}
 @media (min-width:992px){
    h2.pricePagegr-section-title2, h3.pricePagegr-section-title2, h4.pricePagegr-section-title2 {
        margin-bottom:40px;
    }
}
 @media (min-width:992px) and (max-width:1199px){
  
  /* My block out

    body, p, a, ul li, ul li li, a.more {
        font-size:16px;
         line-height:26px;
    }
     h1, h1.s {
        font-size:36px;
         line-height:46px;
    }
     h2, h2 a {
        font-size:26px;
         line-height:36px;
         color:#293443;
    }
     h3, h3 a {
        font-size:24px;
         line-height:34px;
    }
     h4, h4 a {
        font-size:22px;
         line-height:32px;
    }
     h5, h5 a {
        font-size:18px;
         line-height:28px;
    }
     h6, h6 a {
        font-size:16px;
         line-height:26px;
    }

    */
}
 @media (min-width:1200px){
   
   /* My block out

    body, p, a, ul li, ul li li, a.more {
        font-size:16px;
         line-height:26px;
    }
     h1, h1.s {
        font-size:40px;
         line-height:50px;
    }
     h2, h2 a {
        font-size:30px;
         line-height:40px;
         color:#293443;
    }
     h3, h3 a {
        font-size:26px;
         line-height:36px;
    }
     h4, h4 a {
        font-size:22px;
         line-height:32px;
    }
     h5, h5 a {
        font-size:18px;
         line-height:28px;
    }
     h6, h6 a {
        font-size:16px;
         line-height:26px;
    }
    */

}
.pricePagegr-spaces-0 {
    margin-top:0;
     margin-bottom:0;
}
 .pricePagegr-spaces-a {
    margin:5px 0;
}
 .pricePagegr-spaces-b {
    margin:10px 0;
}
 .pricePagegr-top-spaces0 {
    margin-top:0;
}
 .pricePagegr-top-spaces10 {
    margin-top:5px;
}
 .pricePagegr-top-spaces20 {
    margin-top:10px;
}
 .pricePagegr-top-spaces40 {
    margin-top:20px;
}
 .pricePagegr-bottom-spaces-0 {
    margin-bottom:0
}
 .pricePagegr-bottom-spaces-a {
    margin-bottom:5px;
}
 .pricePagegr-bottom-spaces-b {
    margin-bottom:10px;
}
 .pricePagegr-bottom-spaces-c {
    margin-bottom:20px;
}
 @media (min-width:768px){
    .pricePagegr-spaces-a {
        margin:10px 0;
    }
     .pricePagegr-spaces-b {
        margin:20px 0;
    }
     .pricePagegr-top-spaces10 {
        margin-top:10px;
    }
     .pricePagegr-top-spaces20 {
        margin-top:20px;
    }
     .pricePagegr-top-spaces40 {
        margin-top:40px;
    }
     .pricePagegr-bottom-spaces-a {
        margin-bottom:10px;
    }
     .pricePagegr-bottom-spaces-b {
        margin-bottom:20px;
    }
     .pricePagegr-bottom-spaces-c {
        margin-bottom:40px;
    }
     .pricePagegr-multiple-sections-5-enclosure {
        margin:0 -15px;
    }
}
 .pricePagerow {
    margin:0 auto;
}
 .inline-block {
    display:inline-block !important;
     vertical-align:middle;
}
 .pricePagegr-space-fix:after {
    content:"";
     clear:both;
     display:table;
}
 .transparent {
    color:transparent;
     margin-top:-26px;
}
 
.pricePagegr-entry-page-rectangle-container .pricePagegr-entry-page-rectangle-wrap {
    background-repeat:no-repeat;
     background-size:cover;
     background-position:center center;
     background-color:rgba(51, 51, 51, 1);
}
 .pricePagegr-entry-page-rectangle-container .pricePagegr-entry-page-rectangle-wrap .pricePagecol-md-4 {
    display:none;
}
 @media (min-width:992px){
    .pricePagegr-entry-page-rectangle-container {
        position:relative;
    }
     .pricePagegr-entry-page-rectangle-container .pricePagegr-entry-page-rectangle-wrap .pricePagecol-md-4 {
        display:block;
    }
     .pricePagegr-the-low-menu {
        margin-top:-79px;
    }
     .pricePagegr-the-low-menu > div {
        padding-top:79px;
    }
}
 @media (min-width:992px) and (max-width:1199px){
    .pricePagegr-entry-page-rectangle-container .pricePagegr-entry-page-rectangle-wrap .pricePagecol-md-4 > div {
        margin-top:50%;
    }
     .pricePagegr-entry-page-rectangle-container .pricePagegr-entry-page-rectangle-wrap .pricePagecol-md-4 > div img {
        margin-top:-70px;
    }
}
 @media (min-width:1921px){
    .pricePagegr-entry-page-rectangle-container .pricePagegr-entry-page-rectangle-wrap {
        background-size:100% 100%;
    }
}
 .pricePagebtn {
    overflow:hidden;
     padding:8px 10px;
     z-index:2;
     color:#293443;
     border:1px solid #293443;
     font-weight:500;
     font-size:12px;
     line-height:12px;
     background-image:none;
     text-shadow:none;
     font-family:'Monserrat', sans-serif;
     border-radius:3px;
     -moz-border-radius:3px;
     -webkit-border-radius:3px;
}
 .pricePagebtn.pricePageblue, .pricePagebtn.gr-purple, .pricePagebtn.gr-dark-purple,.pricePagebtn.pricePagegreen, .pricePagebtn.red {
    color:#ffffff;
     position:relative;
     transition:all 0.3s ease 0s;
     -moz-transition:all 0.3s ease 0s;
     -webkit-transition:all 0.3s ease 0s;
}
 .pricePagebtn.pricePageblue {
    background-color:#3474ff;
     border:1px solid #3474ff;
}
 .pricePagebtn.gr-purple {
    background-color:#583bec;
     border:1px solid #583bec;
}
.pricePagebtn.gr-dark-purple {
    background-color:#540147;
     border:1px solid #540147;
}
 .pricePagebtn.pricePagegreen {
    background-color:#11c979;
     border:1px solid #11c979;
}
 .pricePagebtn.red {
    background-color:#ec5050;
     border:1px solid #ec5050;
}
 .pricePagebtn.pricePagegr-gtitem-blue, .pricePagebtn.pricePagegr-gtitem-light-blue, .pricePagebtn.pricePagegr-gtitem-white {
    position:relative;
     transition:all 0.3s ease 0s;
     -moz-transition:all 0.3s ease 0s;
     -webkit-transition:all 0.3s ease 0s;
}
 .pricePagebtn.pricePagegr-gtitem-blue {
    border:1px solid #3474ff;
    color:#3474ff !important;
}
 .pricePagebtn.pricePagegr-gtitem-light-blue {
    border:1px solid #00aeff;
    color:#00aeff;
}
 .pricePagebtn.pricePagegr-gtitem-white {
    border:1px solid #ffffff;
    color:#ffffff;
    font-weight: bold !important;
}
 .pricePagebtn.active, .pricePagebtn:active {
    -webkit-box-shadow:none;
     box-shadow:none;
}
 @media (min-width:768px){
    .pricePagebtn {
        padding:10px 12px;
    }
}
 @media (min-width:992px){
    .pricePagebtn {
        font-size:14px;
         line-height:14px;
    }
     .pricePagebtn.pricePageblue:hover, .pricePagebtn.gr-purple:hover, .pricePagebtn.gr-dark-purple:hover,.pricePagebtn.pricePagegreen:hover, .pricePagebtn.red:hover {
        color:#ffffff;
    }
     .pricePagebtn.pricePageblue:after, .pricePagebtn.gr-purple:after,.pricePagebtn.gr-dark-purple:after, .pricePagebtn.pricePagegreen:after, .pricePagebtn.red:after {
        background-color:rgba(0,0,0, 0.2);
         opacity:0.4;
         z-index:-1;
         content:"";
         position:absolute;
         top:0;
         bottom:0;
         left:0;
         right:0;
         width:0;
         transition:all 0.3s ease 0s;
         -moz-transition:all 0.3s ease 0s;
         -webkit-transition:all 0.3s ease 0s;
    }
     .pricePagebtn.pricePageblue:hover:after, .pricePagebtn.gr-purple:hover:after, .pricePagebtn.gr-dark-purple:hover:after, .pricePagebtn.pricePagegreen:hover:after, .pricePagebtn.red:hover:after {
        width:100%;
         transition:all 0.3s ease 0s;
         -moz-transition:all 0.3s ease 0s;
         -webkit-transition:all 0.3s ease 0s;
    }
     .pricePagebtn.pricePagegr-gtitem-blue:hover, .pricePagebtn.pricePagegr-gtitem-light-blue:hover {
        color:#ffffff !important;
        font-weight: bold !important;
    }
     .pricePagebtn.pricePagegr-gtitem-white:hover {
        color:#293443 !important;
        font-weight: bold !important;
    }
     .pricePagebtn.pricePagegr-gtitem-blue:after, .pricePagebtn.pricePagegr-gtitem-light-blue:after, .pricePagebtn.pricePagegr-gtitem-white:after {
        z-index:-1;
         content:"";
         position:absolute;
         top:0;
         bottom:0;
         left:0;
         right:0;
         width:0;
         transition:all 0.3s ease 0s;
         -moz-transition:all 0.3s ease 0s;
         -webkit-transition:all 0.3s ease 0s;
    }
     .pricePagebtn.pricePagegr-gtitem-blue:hover:after, .pricePagebtn.pricePagegr-gtitem-light-blue:hover:after, .pricePagebtn.pricePagegr-gtitem-white:hover:after {
        width:100%;
         transition:all 0.3s ease 0s;
         -moz-transition:all 0.3s ease 0s;
         -webkit-transition:all 0.3s ease 0s;
    }
     .pricePagebtn.pricePagegr-gtitem-blue:after {
        background-color:#3474ff;
    }
     .pricePagebtn.pricePagegr-gtitem-light-blue:after {
        background-color:#00aeff;
    }
     .pricePagebtn.pricePagegr-gtitem-white:after {
        background-color:#ffffff;
    }
}
 .pricePagegr-bottom-border-blue {
    border-bottom:3px solid #3474ff;
}
 
 .pricePagegr-bottom-border-purple {
    border-bottom:3px solid #583bec;
}
.pricePagegr-bottom-border-dark-purple {
    border-bottom:3px solid #540147;
}
 .pricePagegr-bottom-border-green {
    border-bottom:3px solid #11c979;
}
 #next .hover {
    display:none;
}
 @media screen and (min-width:992px) {
     .pricePageheader .pricePagerow .col-md-3 img {
        width:199px;
    }
}
 .pricePageseparator {
    display:block;
     border-bottom:1px solid #e0e7ef;
     height:1px;
     width:100%;
}
 .pricePagecontent {
    width:100%;
     overflow:hidden;
}
 .pricePagegr-encloser-container {
    padding:20px 0;
}
 .pricePagecontainer.pricePageignore-additional-spaces {
    padding:0 15px;
}
.pricePagecontainerPrice.pricePageignore-additional-spaces {
    padding:0 15px;
}
 .pricePagecontainer.center, .icon.center, .pricePagetext-center {
    text-align:center;
}
.pricePagecontainerPrice.center, .icon.center, .pricePagetext-center {
    text-align:center;
}
 .left {
    float:left;
}
 .right {
    float:right !important;
}
 .relative {
    position:relative;
}
 
 @media (min-width:320px) and (max-width:767px){
    .pricePageignore-additional-spaces {
        padding:0;
    }
}
 @media (min-width:768px){
    .pricePagegr-encloser-container {
        padding:40px 0;
    }
     .pricePageignore-additional-spaces {
        padding:0;
    }
}
 @media (min-width:992px){
    .pricePagecontainer.pricePageignore-additional-spaces {
        padding:0;
    }
    .pricePagecontainerPrice.pricePageignore-additional-spaces {
        padding:0;
    }
}
 .pricePagegr-enclosed-rotate-wrap {
    position:relative;
     z-index:1;
}
 .pricePagegr-enclosed-rotate {
    position:relative;
     overflow:hidden;
     padding-bottom:110px;
}
 .pricePagewrapper {
    width:100%;
     height:100%;
}
  .pricePagegr-encloser-white {
    background-color:#ffffff;
}
  .pricePagegr-encloser-blue {
    background-color:#3474ff;
}
  .pricePagegr-encloser-blue-light {
    background-color:#00aeff;
}
  .pricePagegr-encloser-purple {
    background-color:#583bec;
}
  .pricePagegr-encloser-green {
    background-color:#11c979;
}
.pricePagegr-encloser-dark-purple {
    background-color:#540147;
}

  .pricePagegr-encloser-gray {
    background-color:#e0e7ef;
}

 .pricePagegr-color-white, .pricePagegr-color-white:hover, .pricePagegr-color-white:active, .pricePagegr-color-white:focus, .pricePagewhite {
    color:#ffffff;
}
 .pricePagecolorBlueLight, .pricePagecolorBlueLight:hover, .pricePagecolorBlueLight:active, .pricePagecolorBlueLight:focus, .pricePageblue {
    color:#00aeff;
}
.pricePagetext {
    font-size:16px;
     line-height:25px;
     font-weight:700;
     color:#ffffff;
}
div.pricePagetext {
    background-color:#3474ff;
     border-radius:90px;
     -webkit-border-radius:90px;
     -moz-border-radius:90px;
     padding:0 5px;
}
 .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure {
    margin-bottom:0;
     padding-top:10px;
     text-align:center;
}
 .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-white {
    max-width:140px;
     display:inline-block;
}
 .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-white .col-sm-2 {
    text-align:center;
}
 .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-whiteTitle {
    display:none;
}
 @media (min-width:768px){
    .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure {
    }
}
 @media (min-width:992px){
    .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure {
        margin-bottom:0;
         padding-top:0;
    }
     .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-white {
        max-width:initial;
         display:block;
    }
     .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-white img {
        max-width:150px;
    }
     .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-whiteTitle {
        display:block;
         margin-top:18px;
         text-align:left;
         font-size:13px;
         line-height:18px;
         border-right:1px solid rgba(255, 255, 255, 0.5);
    }
}
 @media (min-width:1200px){
    .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-whiteTitle {
        font-size:16px;
         line-height:20px;
         margin-top:16px;
    }
}
 
 @keyframes icon-effect {
    16.65% {
        transform:translateY(8px);
    }
     33.3% {
        transform:translateY(-6px);
    }
     49.95% {
        transform:translateY(4px);
    }
     66.6% {
        transform:translateY(-2px);
    }
     83.25% {
        transform:translateY(1px);
    }
     100% {
        transform:translateY(0px);
    }
}
 @keyframes box-special-effect {
    16.65% {
        transform:translateY(30px);
    }
     33.3% {
        transform:translateY(-6px);
    }
     49.95% {
        transform:translateY(15px);
    }
     66.6% {
        transform:translateY(-2px);
    }
     83.25% {
        transform:translateY(5px);
    }
     100% {
        transform:translateY(0px);
    }
}
 .roll-effect-link-box.pricePagewhite .roll-effect-link-box.pricePagewhite .learn_more_effect:hover {
    color:#ffffff;
}
 @media (min-width:768px){
     .roll-effect-link-box:before {
        margin-top:-4px;
    }
}
 @keyframes circle-pop {
    0% {
        transform:scale(0);
         opacity:0.0;
    }
     60% {
        transform:scale(1.1);
    }
     80% {
        transform:scale(0.9);
         opacity:1;
    }
     100% {
        transform:scale(1);
         opacity:1;
    }
}
 @-webkit-keyframes circle-pop {
    0% {
        -webkit-transform:scale(0);
         opacity:0.0;
    }
     60% {
        -webkit-transform:scale(1.1);
    }
     80% {
        -webkit-transform:scale(0.9);
         opacity:1;
    }
     100% {
        -webkit-transform:scale(1);
         opacity:1;
    }
}
.pricePagegr-encloser-cookie {
    display:none;
     position:fixed;
     z-index:1000;
     background-color:#ffffff;
     bottom:100px;
     right:15px;
     width:300px;
     box-shadow:0 0 6px 2px rgba(0, 0, 0, 0.2);
     padding:15px;
     border:1px solid rgba(0,0,0,0.2);
}
 .pricePagegr-encloser-cookie .pricePagegr-cookie-head {
    padding:10px 0;
}
 .pricePagegr-encloser-cookie .pricePagegr-cookie-head .pricePagegr-cookie-title {
    font-size:14px;
     line-height:14px;
     float:left;
     font-weight:600;
}
 .pricePagegr-encloser-cookie .pricePagegr-cookie-head .pricePagegr-cookie-permission {
    font-size:14px;
     line-height:14px;
     float:right;
     padding-right:20px;
     position:relative;
     cursor:pointer;
     font-weight:500;
}
 .pricePagegr-encloser-cookie .pricePagegr-cookie-head .pricePagegr-cookie-permission:before {
    content:"";
     position:absolute;
     width:2px;
     height:14px;
     top:50%;
     right:8px;
     margin-top:-7px;
     border-right:2px solid #3474ff;
     transform:rotate(45deg);
     -moz-transform:rotate(45deg);
     -webkit-transform:rotate(45deg);
}
 .pricePagegr-encloser-cookie .pricePagegr-cookie-head .pricePagegr-cookie-permission:after {
    content:"";
     position:absolute;
     width:14px;
     height:2px;
     top:6px;
     right:2px;
     border-bottom:2px solid #3474ff;
     transform:rotate(45deg);
     -moz-transform:rotate(45deg);
     -webkit-transform:rotate(45deg);
}
 .pricePagegr-encloser-cookie .pricePagegr-cookie-content {
    font-size:14px;
     line-height:24px;
}
 @media screen and (min-width:479px) {
}
 .pricePagegr-enclose-all {
    margin-top:59px;
     background-color:#ffffff;
}
 .pricePagegr-enclose-all.homePage.banner {
    margin-top:105px;
}
 @media screen and (min-width:594px){
    .pricePagegr-enclose-all {
        margin-top:59px;
    }
     .pricePagegr-enclose-all.homePage.banner {
        margin-top:105px;
    }
}
 @media (min-width:768px){
    .pricePagegr-enclose-all.homePage.banner {
        margin-top:95px;
    }
}
 @media (min-width:992px){
    .pricePagegr-enclose-all {
        margin-top:79px;
    }
     .pricePagegr-enclose-all.homePage.banner {
        margin-top:112px;
    }
}
 @media screen and (min-width:594px){
}
 @media screen and (min-width:768px){
}
#mobilePrimaryMenu {
    padding:10px 5px;
}
 @media (min-width:480px){
    #mobilePrimaryMenu {
        padding:10px 15px;
    }
}
 .pricePageheader {
    z-index:1000;
     width:100%;
     position:fixed;
}
 .pricePageheader.active {
    box-shadow:0 7px 10px -12px rgba(52, 116, 255, 1);
}
 .pricePageheader .pricePagerow .col-md-3 img {
    width:150px;
}
 .pricePageheader > div+div+div+div {
    border-bottom:1px solid rgba(41, 52, 67, 0.3);
}
 .pricePageheader > div+div+div+div.active {
    border-bottom:none;
}
.pricePageheader .pricePagegr-mobile-top-menu-encloser {
    background-color:#ffffff;
     box-shadow:0 7px 10px -12px rgba(52, 116, 255, 1);
}
#primaryMenu ul li, #mobilePrimaryMenu ul li {
    list-style-type:none;
     list-style-image:none;
}
  #mobilePrimaryMenu .pricePagenav > li > a:hover, #mobilePrimaryMenu .pricePagenav > li > a:focus {
    background-color:#3474ff;
}
#mobilePrimaryMenu .pricePagenavbar-nav {
    margin:7.5px 0;
}
 #mobilePrimaryMenu .pricePagenavbar-collapse {
    max-height:initial;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side {
    float:left;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side > div {
    float:left;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button {
    margin-right:10px;
     cursor:pointer;
     float:left;
     height:33px;
     width:33px;
     padding:8px 6px;
     position:relative;
     transform:rotate(0deg);
     transition:all 0.5s ease-in-out 0s;
     border:1px solid rgba(52, 116, 255, 0.3);
     border-radius:3px;
     -moz-border-radius:3px;
     -webkit-border-radius:3px;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button span {
    background:#3474ff none repeat scroll 0 0;
     border-radius:0;
     display:block;
     height:3px;
     left:6px;
     right:6px;
     opacity:1;
     position:absolute;
     transform:rotate(0deg);
     transition:all 0.25s ease-in-out 0s;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button span:nth-child(1) {
    top:8px;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button span:nth-child(2) {
    top:50%;
     margin-top:-1.5px;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button span:nth-child(4) {
    bottom:8px;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button.active span:nth-child(1) {
    top:18px;
     width:0%;
     left:50%;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button.active span:nth-child(2) {
    -webkit-transform:rotate(45deg);
     -moz-transform:rotate(45deg);
     -o-transform:rotate(45deg);
     transform:rotate(45deg);
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button.active span:nth-child(3) {
    top:14px;
     -webkit-transform:rotate(-45deg);
     -moz-transform:rotate(-45deg);
     -o-transform:rotate(-45deg);
     transform:rotate(-45deg);
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button.active span:nth-child(4) {
    top:18px;
     width:0%;
     left:50%;
}
 .pricePageheader.active #mobilePrimaryMenu .pricePagegr-mobile-menu-left-side .pricePagegr-hamburger-button span {
    background:#3474ff none repeat scroll 0 0;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-logo-container a {
    display:block;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-logo-container .pricePagelogo {
    width:30px !important;
}
 @media (min-width:479px) and (max-width:767px) {
}
#mobilePrimaryMenu .pricePagegr-mobile-menu-right-side, #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side > div {
    float:right;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-mobile-menu-login-container {
    margin-right:10px;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-mobile-menu-login-container a {
    display:block;
     line-height:30px;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-tablet-tick-contact-container {
    display:none;
     margin-left:10px;
}
 #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-tablet-tick-button-container {
    display:none;
     margin-right:10px;
     line-height:30px
}
 @media (min-width:320px) and (max-width:479px) {
    #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-mobile-menu-signup-container a, #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-tablet-tick-contact-container a {
        font-size:10px;
         font-weight:300;
         padding:8px 5px;
    }

    .pricePagegr-special-pricing-submenu-enclosureBox { display: none; }
}
 @media (min-width:768px) {
    #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-tablet-tick-contact-container, #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-tablet-tick-button-container {
        display:inline-block;
    }
     #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-tablet-tick-button-container, #mobilePrimaryMenu .pricePagegr-mobile-menu-right-side .pricePagegr-mobile-menu-login-container {
        margin-right:20px;
    }
}
 .pricePageheader .pricePagegr-tablet-special-search {
    padding:50px 0 20px;
     text-align:center;
     border-top:1px solid #3474ff;
     margin:10px -15px 0;
     display:none;
}
 .pricePageheader .pricePagegr-tablet-special-search.active {
    display:block;
}
 .pricePageheader .pricePagegr-desktop-search {
    padding:50px 0 50px;
     text-align:center;
     border-top:1px solid #e0e7e7;
     margin:10px 0 0;
     display:none;
     position:absolute;
     left:0;
     right:0;
     background-color:#ffffff;
     box-shadow:0 10px 10px -12px rgba(41, 52 ,67, 1)
}
 .pricePageheader .pricePagegr-desktop-search:before {
    content:"";
     position:absolute;
     border-bottom:1px solid #3474ff;
     left:-100%;
     right:100%;
     display:block;
     top:-1px;
    ;
}
 .pricePageheader .pricePagegr-desktop-search:after {
    content:"";
     position:absolute;
     border-bottom:1px solid #3474ff;
     left:100%;
     right:-100%;
     display:block;
     top:-1px;
    ;
}
 .pricePageheader .pricePagegr-desktop-search.active {
    display:block;
}
.pricePageheader .pricePagegr-mobile-secondary-menu {
    border-top:none;
     background-color:#ffffff;
     display:block;
     position:fixed;
     top:53px;
     right:0;
     left:0;
     bottom:0;
     overflow-y:scroll;
     height:0;
     z-index:300;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu.active {
    border-top:1px solid #3474ff;
     height:auto;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu:before {
    bottom:0;
     content:"";
     height:100%;
     left:-26px;
     position:absolute;
     right:0;
     top:0;
     transition:all 0s ease 0s;
     width:0;
     z-index:-1;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu.active:before {
    display:none;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu > ul {
    padding-top:10px;
     padding-bottom:68px;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu ul li a {
}
 .pricePageheader .pricePagegr-mobile-secondary-menu > ul > li > a {
    font-size:15px;
     color:#293443;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu > ul > li > a:hover {
    color:#ffffff;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu .pricePagedropDownMobile {
    display:none;
     padding:0 15px;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu .pricePagedropDownMobile li a {
    font-size:15px;
     font-weight:300;
     padding-left:30px;
     line-height:30px;
     color:#293443;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu .pricePagedropDownMobile li a:hover {
    color:#00aeff;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu .pricePagedropDownMobile li.pricePagemobileSubtitle a {
    font-weight:400;
     padding-left:0;
}
 .pricePageheader .pricePagegr-mobile-secondary-menu .pricePagedropDown {
    position:relative;
}

.pricePageheader .pricePagegr-mobile-secondary-menu .pricePagedropDown {
    margin-bottom:1px;
}
 .pricePageheader.homePage .pricePagegr-mobile-secondary-menu {
    top:53px;
}
 .pricePageheader.homePage.active .pricePagegr-mobile-secondary-menu {
    top:54px;
}
 @media (min-width:480px) {
    .pricePageheader .pricePagegr-mobile-secondary-menu {
        right:auto;
         width:350px;
         top:64px;
         left:5px;
         box-shadow:0 0 6px 0 rgba(52, 116, 255, 1);
    }
     .pricePageheader .pricePagegr-mobile-secondary-menu.active {
        border:1px solid #ffffff;
    }
     .pricePageheader.homePage .pricePagegr-mobile-secondary-menu {
        top:58px;
    }
     .pricePageheader.homePage.active .pricePagegr-mobile-secondary-menu {
        top:58px;
    }
}
 @media (min-width:768px) {
     .pricePageheader .pricePagegr-mobile-secondary-menu.active {
        height:458px;
    }
     .pricePageheader .pricePagegr-mobile-secondary-menu > ul {
        padding-bottom:0;
    }
}
@media (min-width:768px){
    #mobilePrimaryMenu .pricePagenavbar-header, #mobilePrimaryMenu .pricePagenavbar-nav, #mobilePrimaryMenu .pricePagenavbar-nav > li {
        float:none;
    }
}
 @media (min-width:992px){
    #mobilePrimaryMenu {
        display:none;
    }
}
 .pricePageheader .pricePagegr-desktop-main-menu-wrap {
    background-color:transparent;
     border-bottom:1px solid rgba(255, 255, 255, 0.12);
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-wrap {
    background-color:#ffffff;
     border-bottom:1px solid transparent;
}
 .pricePagegr-desktop-main-menu-container {
    display:none;
}
 @media (min-width:992px){
    .pricePagegr-desktop-main-menu-container.pricePagecontainer.pricePageignore-additional-spaces {
        display:block;
         padding:20px 0;
    }
    .pricePagegr-desktop-main-menu-container.pricePagecontainerPrice.pricePageignore-additional-spaces {
        display:block;
         padding:20px 0;
    }
}
 @media (min-width:1600px){
    .pricePagegr-desktop-main-menu-container.pricePagecontainer.pricePageignore-additional-spaces {
        padding:16px 0;
    }
    .pricePagegr-desktop-main-menu-container.pricePagecontainerPrice.pricePageignore-additional-spaces {
        padding:16px 0;
    }
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagenavbar-nav {
    float:none;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu.pricePagenavbar {
    min-height:auto;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu {
    margin:0;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu ul {
    padding:0;
     margin:0;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu ul li, .pricePagegr-desktop-main-menu-container .pricePagegr-desktop-menu #primaryMenu ul li a {
    font-family:'Monserrat', sans-serif;
     font-size:11px;
     line-height:11px;
     display:block;
     list-style-type:none;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagelogo {
    width:55px !important;
     margin:0 5px 0 0;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagelogo.pricePagegr-scroll-view {
    display:none;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagelogo.pricePagegr-normal-view {
    display:none;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagelogo.pricePagegr-scroll-view {
    display:block;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagedropDown > ul {
    display:none;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu  {
    float:left;
     z-index:100;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu {
    float:right;
     z-index:99;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown:first-child {
    position:relative;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown:first-child:before {
    content:"";
     position:absolute;
     width:5px;
     height:40px;
     top:0;
     left:-5px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap li.pricePagegr-desktop-login a {
    line-height:28px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown, .pricePagegr-desktop-main-menu-container .pricePagegr-desktop-menu #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-single-link {
    float:left;
     position:relative;
     padding:20px 0 0;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-desktop-login, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-contact-more-info-white, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-signup-nomoney-menu {
    padding:10px 0 0;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown > a {
    position:relative;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown > a:before {
    left:0;
     right:0;
     top:12px;
     height:26px;
     position:absolute;
     content:"";
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li > ul {
    position:absolute;
     top:50px;
     left:0;
     box-shadow:0 0px 30px -12px rgba(41, 52, 67, 1)
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li > ul:before {
    content:"";
     position:absolute;
     width:16px;
     height:16px;
     top:-8px;
     left:20px;
     background-color:#ffffff;
     transform:rotate(45deg);
     -moz-transform:rotate(45deg);
     -webkit-transform:rotate(45deg);
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li > ul:after {
    content:"";
     position:absolute;
     left:0;
     right:0;
     top:-40px;
     height:40px;
     z-index:-1;
     display:block;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagerightRefferer > ul {
    left:auto;
     right:0;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagerightRefferer > ul:before {
    left:auto;
     right:20px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li {
    float:left;
     margin:0 5px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown {
    background-color:#ffffff;
     padding:20px;
     margin:0;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown li {
    display:block;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown ul.pricePagecolAll {
    display:table-cell;
     padding:0;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown.pricePagecol3 ul.pricePagecolAll.pricePagecol2 {
    padding:0 30px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown.pricePagecol2 ul.pricePagecolAll.pricePagecol2 {
    padding:0 0 0 30px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown ul.pricePagecolAll li a {
    white-space:nowrap;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown ul.pricePagecolAll .pricePagegr-middle-list li {
    padding-bottom:20px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown ul.pricePagecolAll .pricePagegr-top-list {
    display:block;
     margin-bottom:30px;
     padding-bottom:15px;
     border-bottom:1px solid rgba(41, 52, 67, 0.2);
     font-size:13px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown ul.pricePagecolAll .pricePagegr-top-list a {
    font-weight:500;
     color:#293443;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown ul.pricePagecolAll .pricePagegr-top-list a:hover, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown ul.pricePagecolAll .pricePagegr-top-list a:active, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown ul.pricePagecolAll .pricePagegr-top-list a:focus {
    color:#3474ff;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown .pricePagegr-middle-list ul {
    display:table-cell;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown.pricePagecol3 .pricePagegr-middle-list ul.pricePagegr-menu-left-side {
    padding-right:10px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown.pricePagecol2 .pricePagegr-middle-list ul.pricePagegr-menu-left-side {
    padding-right:20px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li a.pricePagebtn.pricePagegr-gtitem-white, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li a.pricePagebtn.pricePagegr-gtitem-blue, .pricePagegr-desktop-main-menu-container .pricePagegr-desktop-menu #primaryMenu .pricePagegr-main-menu-wrap > li a.pricePagebtn.pricePagegreen {
    padding:8px 5px;
     font-size:10px;
     line-height:10px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li a span.pricePagegr-menu-login-text {
    display:none;
}
 .pricePageheader .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > a {
    color:#ffffff;  /*  TOP NAV BUTTONS COLORS */
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > a, .pricePageheader .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > ul a {
    color:#293443;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > a:hover, .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > a:active, .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > a:focus, .pricePageheader .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > ul a:hover, .pricePageheader .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > ul a:focus, .pricePageheader .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li > ul a:active {
    color:#3474ff;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(8) > a {
    color:#3474ff;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(8) > a:hover, .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(8) > a:focus, .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(8) > a:active, .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(10) > a, .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(10) > a:hover, .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(10) > a:active, .pricePageheader.active .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(10) > a:focus {
    color:#ffffff;
}
 .pricePageheader .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(9) > a:hover, .pricePageheader .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(9) > a:active, .pricePageheader .pricePagegr-desktop-main-menu-wrap .pricePagegr-main-menu-wrap > li:nth-child(9) > a:focus {
    color:#293443;
}
 .pricePageheader .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown > a:after {
    border-bottom:1px solid rgba(255, 255, 255, 1);
     border-left:1px solid rgba(255, 255, 255, 1);
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown > a:after {
    border-bottom:1px solid rgba(41, 52, 67, 0.5);
     border-left:1px solid rgba(41, 52, 67, 0.5);
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-desktop-login-white {
    display:none;
}
 .pricePageheader .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-desktop-login-blue {
    display:none;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-desktop-login-blue {
    display:block;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li > a.pricePagebtn.pricePagegr-gtitem-white {
    display:none;
}
 .pricePageheader .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li > a.pricePagebtn.pricePagegr-gtitem-blue {
    display:none;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li > a.pricePagebtn.pricePagegr-gtitem-blue {
    display:block;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-contact-more-info-blue {
    padding:10px 0 0;
}
 .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-contact-more-info-white {
    padding:0;
}
 @media (min-width:992px) {
    .pricePagegr-desktop-main-menu-container #primaryMenu ul li, .pricePagegr-desktop-main-menu-container .pricePagegr-desktop-menu #primaryMenu ul li a {
        font-size:12px;
         line-height:14px;
    }
}
 @media (min-width:1200px) {
    .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown.pricePagecol3 .pricePagemenu-dropdown .pricePagegr-middle-list ul li {
        padding-left:20px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown .pricePagegr-middle-list ul span {
        display:block;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown > a {
        padding-right:10px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown > a:after {
        top:50%;
         width:6px;
         height:6px;
         right:0;
         margin-top:-4px;
         border-bottom:1px solid rgba(41, 52, 67, 0.5);
         border-left:1px solid rgba(41, 52, 67, 0.5);
         position:absolute;
         content:"";
         transform:rotate(-45deg);
         -moz-transform:rotate(-45deg);
         -webkit-transform:rotate(-45deg);
    }
}
 @media (min-width:1600px) {
    .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagelogo {
        width:55px !important;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li a.pricePagebtn.pricePagegr-gtitem-white, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li a.pricePagebtn.pricePagegr-gtitem-blue, .pricePagegr-desktop-main-menu-container .pricePagegr-desktop-menu #primaryMenu .pricePagegr-main-menu-wrap > li a.pricePagebtn.pricePagegreen {
        padding:10px 12px;
         font-size:14px;
         line-height:14px;
    }
     .pricePageheader .pricePagegr-desktop-menu {
        margin:0 -190px 0 -187px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li {
        margin-right:10px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown > a {
        padding-right:15px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap li.pricePagegr-desktop-login a {
        line-height:34px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown, .pricePagegr-desktop-main-menu-container .pricePagegr-desktop-menu #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-single-link {
        padding:18px 0 0;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-desktop-login, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-contact-more-info-white, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-signup-nomoney-menu, .pricePageheader.active .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-contact-more-info-blue {
        padding:6px 0 0;
    }
}
 @media (min-width:1900px) {
    .pricePageheader .pricePagegr-desktop-menu {
        margin:0 -360px 0 -187px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li a span.pricePagegr-menu-login-text {
        display:inline-block;
         vertical-align:middle;
         padding:0 0 0 5px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-contact-more-info {
        margin:0 10px 0 20px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagegr-desktop-login {
        margin:0;
    }
}
.pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagedropDown .pricePagemenu-dropdown.pricePagegr-wrap-col2 > li {
    display:table-cell;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagedropDown > ul.pricePagegr-wrap-col2 > li.pricePagegr-wrap-col-left {
    padding-right:30px;
}
 .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagedropDown > ul.pricePagegr-wrap-col2 > li.pricePagegr-wrap-col-left ul.pricePagecol1, .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagedropDown > ul.pricePagegr-wrap-col2 > li.pricePagegr-wrap-col-left ul.pricePagecol2 {
    padding-right:30px;
}
 @media (min-width:992px) and (max-width:1199px) {
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagerightRefferer.pricePageresources > ul {
        right:-270px;
    }
     .pricePagegr-desktop-main-menu-container #primaryMenu .pricePagegr-main-menu-wrap > li.pricePagerightRefferer.pricePageresources > ul:before {
        right:290px;
    }
}
 @media screen and (min-width:768px) {
    .pricePagenav.bottom li {
        float:left;
    }
}
 .pricePagefooter {
    display:block;
     background-color:#333333;
}
 .pricePagefooter ul {
    padding:0;
     margin:0;
}
 .pricePagefooter ul li {
    list-style-type:none;
     list-style-image:none;
     font-size:12px;
     line-height:12px;
     font-family:'Monserrat', sans-serif;
}
 .pricePagefooter ul li a, .pricePagefooter ul li a:hover, .pricePagefooter ul li a:active, .pricePagefooter ul li a:focus {
    display:block;
     color:#c3c3c3;
     font-family:'Monserrat', sans-serif;
     font-size:12px;
     line-height:12px;
     padding:8px 0;
}
 .pricePagefooter ul li.pricePagegr-footer-enclose-1 > a, .pricePagefooter ul li.pricePagegr-footer-enclose-1 > a:hover, .pricePagefooter ul li.pricePagegr-footer-enclose-1 > a:active, .pricePagefooter ul li.pricePagegr-footer-enclose-1 > a:focus {
    color:#ffffff;
}
 .pricePagefooter ul li.pricePagegr-footer-copyright {
    color:#c3c3c3;
}
 .pricePagefooter ul li.pricePagegr-footer-enclose-1 h5 {
    color:#ffffff;
}
 .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll .pricePagegr-footer-enclose-1, .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll .pricePagegr-footer-enclose-1 a {
    font-weight:500;
}
 .pricePagefooter .pricePagegr-footer-bottom-wrap {
    padding:20px 0;
     border-top:1px solid rgba(255, 255, 255, 0.15);
}
 .pricePagefooter .pricePagegr-footer-bottom-wrap ul li {
    padding:0;
}
 .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagecolAll, .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagecolAll a {
    line-height:24px;
}
 .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-social-box .pricePagesocial li, .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-social-box .pricePagesocial li {
    float:right;
     padding:0 5px;
}
 .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-social-box .pricePagesocial li a, .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-social-box .pricePagesocial li a {
    line-height:24px;
     display:inline-block;
}
 .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-enclose-logo-wrap .pricePagegr-footer-enclose-logo {
    width:160px;
}
 .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-copyright {
    margin-right:20px;
}
 .pricePagefooter .pricePagegr-footer-bottom-wrap li, .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a, .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a:focus, .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a:active, .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a:hover {
    padding:0;
     line-height:24px;
}
 .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-social-box .pricePagesocial {
    margin:0 auto;
    width:176px;
}
 @media (min-width:320px) and (max-width:767px){
    .pricePagefooter ul li, .pricePagefooter ul li a {
        text-align:center;
    }
     .pricePagefooter .pricePagecolAll {
        border-top:1px solid rgba(41, 52, 67, 0.2);
         padding:20px 0;
    }
     .pricePagefooter .pricePagecolAll.pricePagecol1 {
        border-top:none;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-enclose-logo-wrap .pricePagegr-footer-enclose-logo {
        margin:0 auto;
         display:block;
    }
     .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a {
        text-align:center;
    }
     .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a, .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a:focus, .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a:active, .pricePagefooter ul > li.pricePagegr-footer-enclose-1 > a:hover {
        display:inline-block;
         padding-right:15px;
         position:relative;
         font-size:14px;
    }
     .pricePagefooter ul > li.pricePagegr-footer-enclose-1.active > a{
        margin-bottom:10px;
    }
     .pricePagefooter ul li.pricePagegr-footer-enclose-2 {
        display:none;
    }
     .pricePagefooter ul li.pricePagegr-footer-enclose-2.active {
        display:block;
    }
     .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-footer-enclose-policy .pricePageseparator {
        display:none;
    }
}
 @media (min-width:768px){
    .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol1 {
        border-bottom:1px solid rgba(41, 52, 67, 0.2);
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll {
        float:left;
    }
     .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-footer-enclose-policy {
        float:left;
    }
     .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-footer-enclose-policy a {
        float:left;
         width:auto;
    }
     .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-footer-enclose-policy > a:first-child {
        margin-right:20px;
    }
     .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-footer-enclose-policy .pricePageseparator {
        display:block;
         float:left;
         width:auto;
         padding:0 10px;
         border-bottom:none;
    }
     .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-footer-copyright {
        float:right;
    }
}
 @media (min-width:768px) and (max-width:991px){
    .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol2 li a, .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol3 li a, .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol4 li a, .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol5 li a, .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol6 li a {
        font-size:11px;
         line-height:14px;
    }
}
 @media (min-width:768px) and (max-width:1199px){
    .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-enclose-logo-wrap .pricePagegr-footer-enclose-logo {
        margin:0 auto;
         display:block;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol1 {
        width:100%;
         margin-right:0;
         margin-bottom:5%;
         text-align:center;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol2 {
        width:19%;
         margin-right:1%;
         margin-bottom:5%;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol3 {
        width:15%;
         margin-right:1%;
         margin-bottom:5%;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol4 {
        width:18%;
         margin-right:1%;
         margin-bottom:5%;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol5 {
        width:18%;
         margin-right:1%;
         margin-bottom:5%;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol6 {
        width:26%;
         margin-right:0;
         margin-bottom:5%;
    }
}
 @media (min-width:992px){
    .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagecolAll, .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagecolAll a, .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagecolAll a:hover, .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagecolAll a:active, .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagecolAll a:focus, .pricePagefooter .pricePagegr-footer-bottom-wrap .pricePagegr-social-box .pricePagesocial li {
        line-height:28px;
         padding-top:0;
         padding-bottom:0;
    }
}
 @media (min-width:1200px){
    .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-social-box .pricePagesocial {
        margin:0 0 0 -15px;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol1 {
        width:27%;
         margin-right:2%;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol2 {
        width:13%;
         margin-right:2%;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol3 {
        width:10%;
         margin-right:2%;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol4 {
        width:12%;
         margin-right:2%;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol5 {
        width:11%;
         margin-right:2%;
         padding-bottom:10px;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol6 {
        width:17%;
         margin-right:0;
    }
     .pricePagefooter .pricePagegr-footer-top-wrap .pricePagegr-footer-top .pricePagecolAll.pricePagecol1 {
        border-bottom:none;
    }
}
.pricePagegr-enclose-g-container {
    background-color:#333333;
}

 .pricePagegr-enclose-g-container .pricePagegr-enclosed-rotate {
    padding:40px 0 80px;
     background-color:rgba(51, 51, 51, 1);
     background-image:url("../../img/website-images/footer-cta-ribbon-bg.png");
     background-size:cover;
}


 .pricePagegr-enclose-g-container .pricePagegr-start-text-enclosure {
    padding-bottom:20px;
}
 @media (min-width:768px) and (max-width:991px){
    .pricePagegr-enclose-g-container .pricePagegr-start-button-enclosure a {
        margin:0 10px;
    }
}
 @media (min-width:1200px) and (max-width:1920px){
    .pricePagegr-enclose-g-container .pricePagegr-enclosed-rotate {
        padding:40px 0 150px;
    }
}
 @media (min-width:1200px){
    .pricePagegr-enclose-g-container .pricePagegr-start-button-enclosure a {
        margin:0 10px;
    }
}
 @media (min-width:1921px){
     .pricePagegr-enclose-g-container .pricePagegr-enclosed-rotate {
        padding:40px 0;
    }
     .pricePagegr-enclose-g-container .pricePagegr-enclosed-rotate:after {
        display:none;
    }
     .pricePagegr-enclose-g-container .pricePagegr-start-button-enclosure {
        padding-top:8px;
    }
     .pricePagegr-enclose-g-container .pricePagegr-start-text-enclosure {
        padding-bottom:0;
    }
}
 
 @-webkit-keyframes donut_box_1 {
    from {
        -webkit-transform:rotate(180deg);
    }
     to {
        -webkit-transform:rotate(252deg);
    }
}
 @keyframes donut_box_1 {
    from {
        transform:rotate(180deg);
    }
     to {
        transform:rotate(252deg);
    }
}
 @-webkit-keyframes donut_box_2 {
    from {
        -webkit-transform:rotate(180deg);
    }
     to {
        -webkit-transform:rotate(214.2deg);
    }
}
 @keyframes donut_box_2 {
    from {
        transform:rotate(180deg);
    }
     to {
        transform:rotate(214.2deg);
    }
}
 @-webkit-keyframes donut_box_3 {
    from {
        -webkit-transform:rotate(180deg);
    }
     to {
        -webkit-transform:rotate(216deg);
    }
}
 @keyframes donut_box_3 {
    from {
        transform:rotate(180deg);
    }
     to {
        transform:rotate(216deg);
    }
}
 @-webkit-keyframes donut_box_4 {
    from {
        -webkit-transform:rotate(180deg);
    }
     to {
        -webkit-transform:rotate(212.4deg);
    }
}
 @keyframes donut_box_4 {
    from {
        transform:rotate(180deg);
    }
     to {
        transform:rotate(212.4deg);
    }
}
 @-webkit-keyframes donut_box_5 {
    from {
        -webkit-transform:rotate(180deg);
    }
     to {
        -webkit-transform:rotate(207deg);
    }
}
 @keyframes donut_box_5 {
    from {
        transform:rotate(180deg);
    }
     to {
        transform:rotate(207deg);
    }
}
 @-webkit-keyframes donut_box_6 {
    from {
        -webkit-transform:rotate(180deg);
    }
     to {
        -webkit-transform:rotate(234deg);
    }
}
 @keyframes donut_box_6 {
    from {
        transform:rotate(180deg);
    }
     to {
        transform:rotate(234deg);
    }
}
 
 @media (min-width:768px){
     .pricePagecontainer.pricePageignore-additional-spaces {
        padding:0;
    }
    .pricePagecontainerPrice.pricePageignore-additional-spaces {
        padding:0;
    }
}
 
 .on {
    visibility:visible;
}
 @media (min-width:768px) and (max-width:991px) {
}
 @media (min-width:1200px) {
}
 
/* 
::placeholder {
    color:rgba(41, 52, 67, 0.5) !important;
     opacity:1;
}
 ::-ms-input-placeholder {
    color:rgba(41, 52, 67, 0.5) !important;
}
*/
 @-webkit-keyframes fromRightAnim1 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-webkit-keyframes fromRightAnim2 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-webkit-keyframes fromRightAnim3 {
    0% {
        left:100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-webkit-keyframes fromRightAnim4 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @-webkit-keyframes fromRightAnim5 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-moz-keyframes fromRightAnim1 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-moz-keyframes fromRightAnim2 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-moz-keyframes fromRightAnim3 {
    0% {
        left:100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-moz-keyframes fromRightAnim4 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @-moz-keyframes fromRightAnim5 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-o-keyframes fromRightAnim1 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-o-keyframes fromRightAnim2 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-o-keyframes fromRightAnim3 {
    0% {
        left:100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-o-keyframes fromRightAnim4 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @-o-keyframes fromRightAnim5 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-ms-keyframes fromRightAnim1 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-ms-keyframes fromRightAnim2 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-ms-keyframes fromRightAnim3 {
    0% {
        left:100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-ms-keyframes fromRightAnim4 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @-ms-keyframes fromRightAnim5 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @keyframes fromRightAnim1 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @keyframes fromRightAnim2 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @keyframes fromRightAnim3 {
    0% {
        left:100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @keyframes fromRightAnim4 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @keyframes fromRightAnim5 {
    0% {
        left:100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-webkit-keyframes fromLeftAnim1 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-webkit-keyframes fromLeftAnim2 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-webkit-keyframes fromLeftAnim3 {
    0% {
        left:-100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-webkit-keyframes fromLeftAnim4 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @-webkit-keyframes fromLeftAnim5 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-moz-keyframes fromLeftAnim1 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-moz-keyframes fromLeftAnim2 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-moz-keyframes fromLeftAnim3 {
    0% {
        left:-100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-moz-keyframes fromLeftAnim4 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @-moz-keyframes fromLeftAnim5 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-o-keyframes fromLeftAnim1 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-o-keyframes fromLeftAnim2 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-o-keyframes fromLeftAnim3 {
    0% {
        left:-100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-o-keyframes fromLeftAnim4 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @-o-keyframes fromLeftAnim5 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-ms-keyframes fromLeftAnim1 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-ms-keyframes fromLeftAnim2 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-ms-keyframes fromLeftAnim3 {
    0% {
        left:-100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-ms-keyframes fromLeftAnim4 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @-ms-keyframes fromLeftAnim5 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @keyframes fromLeftAnim1 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @keyframes fromLeftAnim2 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @keyframes fromLeftAnim3 {
    0% {
        left:-100%;
         opacity:0;
    }
     1% {
        left:3%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @keyframes fromLeftAnim4 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:50%;
         opacity:1;
    }
}
 @keyframes fromLeftAnim5 {
    0% {
        left:-100%;
         opacity:0;
    }
     100% {
        left:0%;
         opacity:1;
    }
}
 @-webkit-keyframes toRightAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-webkit-keyframes toRightAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-webkit-keyframes toRightAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     99% {
        left:3%;
         opacity:0;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-webkit-keyframes toRightAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     30% {
        left:55%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-webkit-keyframes toRightAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-moz-keyframes toRightAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-moz-keyframes toRightAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-moz-keyframes toRightAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     99% {
        left:3%;
         opacity:0;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-moz-keyframes toRightAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-moz-keyframes toRightAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-o-keyframes toRightAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-o-keyframes toRightAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-o-keyframes toRightAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-o-keyframes toRightAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     30% {
        left:55%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-o-keyframes toRightAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-ms-keyframes toRightAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-ms-keyframes toRightAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-ms-keyframes toRightAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     99% {
        left:3%;
         opacity:0;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-ms-keyframes toRightAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     30% {
        left:55%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-ms-keyframes toRightAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @keyframes toRightAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @keyframes toRightAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @keyframes toRightAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     99% {
        left:3%;
         opacity:0;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @keyframes toRightAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     30% {
        left:55%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @keyframes toRightAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:100%;
         opacity:0;
    }
}
 @-webkit-keyframes toLeftAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-webkit-keyframes toLeftAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-webkit-keyframes toLeftAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-webkit-keyframes toLeftAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     70% {
        left:30%;
         opacity:0;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-webkit-keyframes toLeftAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:3%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-moz-keyframes toLeftAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-moz-keyframes toLeftAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-moz-keyframes toLeftAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-moz-keyframes toLeftAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     70% {
        left:30%;
         opacity:0;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-moz-keyframes toLeftAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:3%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-o-keyframes toLeftAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-o-keyframes toLeftAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-o-keyframes toLeftAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-o-keyframes toLeftAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     70% {
        left:30%;
         opacity:0;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-o-keyframes toLeftAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:3%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-ms-keyframes toLeftAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-ms-keyframes toLeftAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-ms-keyframes toLeftAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-ms-keyframes toLeftAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     70% {
        left:30%;
         opacity:0;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @-ms-keyframes toLeftAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:3%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @keyframes toLeftAnim1 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @keyframes toLeftAnim2 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:25%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @keyframes toLeftAnim3 {
    0% {
        left:0%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @keyframes toLeftAnim4 {
    0% {
        left:50%;
         opacity:1;
    }
     70% {
        left:30%;
         opacity:0;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}
 @keyframes toLeftAnim5 {
    0% {
        left:0%;
         opacity:1;
    }
     30% {
        left:3%;
         opacity:1;
    }
     100% {
        left:-50%;
         opacity:0;
    }
}

#colorbox{
    position:absolute;
    top:0;
    left:0;
    z-index:9999;
    overflow:hidden;
    outline:none!important;
    box-shadow:none!important;
    -webkit-box-shadow:none!important;
    -moz-box-shadow:none!important
}
#colorbox{
}



  /* Homebuyers.html, property-flipping-investors.html, rental-property-investors.html css */






/******************************************* MY CLASSES **************************************/
/* My radio button CSS */
.medium-radio{ width: 1.6em; height: 1.6em;}

input[type=radio]#HomeRadio {
    accent-color: #3474ff;
    margin-top: -1px;
    vertical-align: middle;
}

input[type=radio]#FlippingRadio {
    accent-color: #3474ff;
    margin-top: -1px;
    vertical-align: middle;
}


input[type=radio]#RentalRadio {
    accent-color: #3474ff;
    margin-top: -1px;
    vertical-align: middle;
}

input[type=radio]#auto {
    accent-color: auto;
    margin-top: -1px;
    vertical-align: middle;
}




/* MOBILE CLASSES  */
@media only screen and (max-width: 600px) {
  
    .pricePagegr-tile-price-top-space {
        padding-top: 80px !important;
    }    

    .pricePagegr-price-amount-top-space {
        padding-top: 20px !important;
    }
}

/* DESKTOP CLASSES - General */
@media screen and (min-width: 780px) {

    .pricePagegr-left-border
    {
        border-left: 1px solid #000 !important;
    }

    .pricePagegr-desktop-min-height {
        min-height: 170px !important;
        height: 170px !important;
    }

}


/*  MY SWITCH BOX CLASSES */

/******   NEW   *******/

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
   /* width: 100px;
    height: 50px; */
    width: 60px;
    height: 34px;
    background-color:#2196F3;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
/****** END NEW *******/

/* The switch - the box around the slider */
.pricePageswitchSpecial {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .pricePageswitchSpecial input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .pricePagesliderSpecial {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:#2196F3;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .pricePagesliderSpecial:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  /* Rounded sliders */
  .pricePagesliderSpecial.pricePageround {
    border-radius: 34px;
  }
  
  .pricePagesliderSpecial.pricePageround:before {
    border-radius: 50%;
  }

/* Done slider */


  /*        PRICE  PAGE    */



  
	/* Pricing */
	
	.pricePagegr-desktop-main-menu-container #primaryMenu ul li.pricePagegr-signup-nomoney-menu { display: none; }
	
	@media (min-width: 1600px){
		.pricePagegr-desktop-main-menu-container #primaryMenu ul li.pricePagegr-signup-nomoney-menu { display: block; visibility: hidden; }
	}
	
	/********** header  **********/
    
    .pricePagegr-entry-page-rectangle-container .pricePagegr-entry-page-rectangle-wrap { background-image: url('../../img/website-images/pricing-header-bg.jpg'); background-repeat: no-repeat; background-size: cover; }
    .pricePagegr-entry-page-rectangle-container .pricePagegr-first-info-special-wrapper { padding: 40px 0; }
    .pricePagegr-entry-page-rectangle-container .pricePagegr-first-info-special-wrapper h4 { padding: 0; margin: 0px; }
    
    @media (min-width: 1200px){
        .pricePagegr-entry-page-rectangle-container .pricePagegr-first-info-special-wrapper { padding: 40px 0 200px; }
    }
    
	/********** END header  **********/
	/********** Section1 **********/
    
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu { text-align: center; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll { position: relative; display: inline-block; vertical-align: top; width: 270px; margin: 10px; padding-top: 40px; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll .pricePagegr-most-popular-wrap { position: relative; padding: 20px 10px 0; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item2 { position: relative; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item2 .pricePagegr-most-popular-wrap { z-index: 1; }

    /* OLD 
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item4 .pricePagegr-most-popular-wrap { background-image: url('../../images/enterprise-background-image.jpg'); background-repeat: no-repeat; background-position: center center; background-size: cover; }
    */

    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul { text-align: left; padding: 10px 30px; min-height: 170px; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll .pricePagegr-most-popular { position: absolute; left: 0; right: 0; height: 40px; line-height: 40px; top: -40px; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll .pricePagegr-substansive-value { font-style: italic; margin-bottom: 20px; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll .pricePagegr-no-credit-card { font-style: italic; font-size: 12px; line-height: 20px; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul { padding-bottom: 30px; margin-top: -5px; padding-left: 20px; padding-right: 0;  }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul li { padding: 5px 0; font-size: 13px; line-height: 24px; list-style-image: none; position: relative; list-style-type: none; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul li:before { content: ""; position: absolute; top: 50%; left: -18px; width: 12px; height: 9px; margin-top: -4.5px; background-repeat: no-repeat; background-position: center center; background-size: 100% 100%; }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul li.pricePageblueCheck:before { background-image: url('../../img/website-images/checked-blue.png'); }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul li.pricePagegreenCheck:before { background-image: url('../../img/website-images/checked-green.png'); }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul li.pricePagepurpleCheck:before { background-image: url('../../img/website-images/checked-purple.png'); }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul li.pricePagegr-dark-purple-check:before { background-image: url('../../img/website-images/checked-dark-purple.png'); }
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item1,
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item3,
    .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item4 { margin-top: -30px; }


	.pricePagesection1 .pricePagedetailsDecide h4 { display: none; }
    
    .pricePagegr-special-pricing-submenu-enclosureBox_XXX { display: none; }
	
    @media (min-width: 614px){
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item1,
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item3,
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item4 { margin-top: 10px; }
       
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll .pricePagegr-most-popular-wrap,
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item2 .pricePagegr-most-popular-wrap { min-height: 626px; }
    }
    
    @media (min-width: 992px){
		.pricePagesection1 .pricePagedetailsDecide h4 { display: block; }
        
		/* .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul { min-height: 210px; } */
    }
    
    @media (min-width: 1200px){

      /* OLD 
		.pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item4 { background-image: url('../../images/pricing-background-image.jpg'); }
    */

        .pricePagesection1.pricePagegr-encloser-container { padding-top: 0; margin-top: -150px; }
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll { float: left; margin: 0; width: 290px; }     /*   width: 390px; tile size    */
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul { min-height: 160px; }
		.pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll ul li { font-size: 14px; line-height: 25px; }
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll .pricePagegr-most-popular-wrap { min-height: 626px; padding: 20px 60px 0; }
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item2 .pricePagegr-most-popular-wrap { min-height: 670px; }
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item2:before { z-index: 1; content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0; box-shadow: 0 10px 30px -5px rgba(41, 52, 67); }
        
        /* OLD 
        .pricePagegr-enclose-pricing-container .pricePagegr-pricing-sub-menu .pricePagegr-pricing-sub-menuItemAll.pricePagegr-pricing-sub-menu-item4 { displayX: none; }
        */

        /*.pricePagegr-special-pricing-submenu-enclosureBox { display: table; padding: 30px; display: block; background-image: url('../../images/pricing-background-image_DELETE.jpg'); background-repeat: no-repeat; background-size: cover; }
        */

        .pricePagegr-special-pricing-submenu-enclosureBox { display: table; padding: 30px; display: block;}
        .pricePagegr-special-pricing-submenu-enclosureBox .pricePagecolItemAll { display: table-cell; vertical-align: top; }
        .pricePagegr-special-pricing-submenu-enclosureBox .pricePagecolItemAll.pricePagecolItem1 { width: 15%; }
        .pricePagegr-special-pricing-submenu-enclosureBox .pricePagecolItemAll.pricePagecolItem5 { width: 15%; }
        .pricePagegr-special-pricing-submenu-enclosureBox .pricePagecolItemAll ul li { padding: 2px 0; }
        
    }
    
	/********** END Section1 **********/
   /********** Section2 **********/
    
    #details { display: none; }
    #details.active { display: block; }
	
    #scrollMenuFixed { display: none; }
    #scrollMenuFixed.active { display: block; }
    
    
	/********** END Section2 **********/	
	/********** Section3 **********/	
	
	.pricePagegr-container1 .pricePagegr-informational-client-whiteWrap { border-top: 1px solid rgba(41, 52, 67, 0.2); }
	.pricePagegr-container1 .pricePagegr-informational-client-white { padding: 10px 0; }
    .pricePagegr-container1 .pricePagegr-informational-client-white span { display: inline-block; vertical-align: middle; }
    .pricePagegr-container1 .pricePagegr-informational-client-white span.pricePagegr-our-care-text { margin: 0 5px; }
    .pricePagegr-container1 .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-whiteTitle { padding: 30px 0; border-right: 1px solid rgba(41, 52, 67, 0.2); margin: 8px 0; }
    
    @media (min-width: 320px) and (max-width: 767px){
		.pricePagegr-container1 .pricePagegr-informational-client-white span.pricePagegr-our-care-text br { display: block; }
	}
	
    @media (min-width: 768px) and (max-width: 991px){
		.pricePagegr-container1 .pricePagegr-informational-client-whiteWrap .pricePagegr-informational-client-white-enclosure .pricePagegr-informational-client-white { max-width: 150px; padding: 5px 18px; }
	}
	
    @media (min-width: 320px) and (max-width: 991px){
        .pricePagegr-container1 .pricePagegr-informational-client-white span { display: block; margin: 0 15px; }
    }
	
	@media (min-width: 992px) {
		.pricePagegr-container1 .pricePagegr-informational-client-white span.pricePagegr-our-care-text { text-align: left; }
	}
	
	@media (min-width: 992px) and (max-width: 1199px){
		.pricePagegr-container1 .pricePagegr-informational-client-white span.pricePagegr-our-care-image { max-width: 100%; width: 50px; }
		.pricePagegr-container1 .pricePagegr-informational-client-white span.pricePagegr-our-care-image img { max-width: 100%; }
		.pricePagegr-container1 .pricePagegr-informational-client-white span.pricePagegr-our-care-text { font-size: 13px; line-height: 20px; }
	}
    
	/********** END Section3 **********/	
	/********** Section4 **********/
	
    .pricePagesection4 { border-top: 1px solid rgba(255, 255, 255, 0.4); }
    
	/********** END Section4 **********/	
	/********** Section5 **********/
    
    @media (min-width: 1200px){
		/* Hide CPQ .section5 .introWrapOptions .introItemAll { width: 236px; float: left; vertical-align: middle; margin: 2px; } */
    }
	
	/* Hide CPQ */
	
	/* Hide CPQ */
	
	/********** END Section5 **********/
	/********** Section6 **********/
	
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll { border-bottom: 1px solid rgba(41, 52, 67, 0.2); }
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll div { padding: 10px 15px 10px 20px; }
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll .pricePagequestion { position: relative; cursor: pointer; }
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll .pricePagequestion:before { content: ""; position: absolute; border-bottom: 2px solid #3474ff; border-right: 2px solid #3474ff; transform: rotate(45deg); width: 10px; height: 10px; top: 8px; right: -7px; }
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll .pricePageanswer { display: none; }
    
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll.active { padding: 15px 0; }
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll.active div { border-left: 3px solid #3474ff; }
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll.active .pricePagequestion { font-weight: 500; }
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll.active .pricePagequestion:before { transform: rotate(-135deg); }
    .pricePagesection6 .pricePagegr-enclose-team-enclosure .pricePageitemAll.active .pricePageanswer { display: block; }
    
    @media (min-width: 768px){
        .pricePagesection6 .pricePagegr-enclose-team-enclosure { margin: 0 -15px; }
    }
    
	/********** END Section6 **********/	
	/********** Section7 **********/
	

	/********** END Section7 **********/
