
  .CircularProgressbar .CircularProgressbar-path {
    stroke: darkgreen;
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar-trail {
    stroke: gray;
  }
  .CircularProgressbar-text {
    fill: yellow;
  }
  .CircularProgressbar-background {
    fill: green;
  }