.miaClass
{
    color:white;
    background-color: blue;
}

.dot {
    height: 16px;
    width: 16px;
    background-color: #bbb;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
  }

.dot-circle {
    height: 16px;
    width: 16px;
    background-color: #000000;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
}

table.calculation-info-table > tr > td {
    color: #5d5d5d;
}

.scrollable-div {
    height: 150px;
    overflow: auto;
    margin: 5px;
    text-align: justify;
    padding: 5px;
  }


@keyframes blink { 
    50% { border-color: #ff0000; } 
 }
 
 .blinkingBorder{ 
     animation: blink .5s step-end infinite alternate;
 }


.progress {
	vertical-align: baseline;
}

.lookup-input {
    border:2px solid #1c7cfb !important;
    color:#000000 !important;
}

.lookup-input::placeholder {
    opacity: 1 !important;
    color: #c7c7c7 !important;
  }

.floatAboveEverything {
	z-index: 1000; 
	position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    text-align: center;
}

.first-nav-item {
    margin-top: 45px;
}

.dash-logo-link {
    min-height: 61px;
}

i.fas.fa-bell.fa-fw {
    color: #137feb;
    font-size: 25px;
}

span.badge.badge-danger.badge-counter {
    background-color: #1c1f81;
    border-radius: 50%;
    padding: 7px 10px;
}

ul.navbar-nav.ml-auto {
    margin-right: 34px;
}

.sidebar-toggled .sidebar .nav-item {
    padding-left: 0;
}

ul.side-bar-bottom-ul {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    padding: 0;
    list-style: none;
    background-color: #3d94eb;
    padding-bottom: 50px;
    position: relative;
}

.sidebar-toggled .sidebar .nav-item a.nav-link {
    display: block !important;
}

.sidebar-toggled .sidebar .nav-item .nav-link span {
    margin-left: 0;
}

.sidebar-toggled .sidebar .nav-item .nav-link div {
    margin-left: 0;
}

button#sidebarToggle {
    position: absolute;
    right: -40px;
    background-color: #3d94eb !important;
    border-radius: 5px 0 0 5px !important;
}


li.nav-item.mydropdown.active.nav-item-dropdown.nav-item-dropdown .collapse.show:before {
    right: -6.8px;
    height: 94.5px;
}

li.nav-item.mydropdown.nav-item-dropdown.active img {
    filter: invert(0);
}

li.nav-item.mydropdown.active .nav-link[data-toggle="collapse"].collapsed::after {
    color: #919198;
    right: 30px;
    top: 14px;
}

li.nav-item.mydropdown.active span.img-side-logo {
    margin-left: 0 !important;
}


li.user-list {
    display: flex;
    align-items: center;
    color: #000;
}

.dialog-form-label {
    color:#818391;
    font-size: 16px;
}

span.person-name {
    font-weight: 600;
    text-transform: uppercase;
    padding-right: 20px;
}

span.person-img-user {
    font-size: 32px;
}

.region-group {
    max-width: 500px;
    width: 100%;
}


    .region-group select#exampleFormControlSelect1   {
        background-color: #2b2b2d;
        border: 0;
        color: #c7c7c8;
    }

    .region-group select#profileSelect {
        background-color: #2b2b2d;
        border: 0;
        color: #c7c7c8;
    }

    .region-group select#investmentTypeSelect {
        background-color: #2b2b2d;
        border: 0;
        color: #c7c7c8;
    }
    


.sidebar a.collapse-item:before {
    content: "-";
    font-weight: 700;
    padding-right: 10px;
}

a.collapse-item {
    color: #000000 !important;
    font-size: 15px;
    padding-left: 45px !important;
}

.sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
    color: #000;
}


.cards-row h3 {
    color: #fff;
    font-weight: 700;
    font-size: 60px;
}

.cards-row h4 {
    color: #fff;
    font-size: 28px;
}

.card-price {
    background-color: #ff9362;
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: 190px;
}

.selected-tab {
    background-color: #4e73df;
    border-color: #aaa;
    color: #ffffff;
    border-radius: 5px 5px 0 0;
}

.voilet-bg {
    background-color: #ff9362;
}

.green-bg {
    background-color: #01a65a;
}

.pink-bg {
    background-color: #ff5b74;
}

.greenish-tick {
    color: #add854;
}

.top-pick-div td i.fas.fa-check-square {
    padding-right: 8px;
    font-size: 24px;
}

.top-pick-div th {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
}

.top-pick-div td {
    color: #ffffff;
    font-size: 18px;
}

.top-pick-div .price-value {
    font-weight: 800;
    font-size: 24px;
}

.top-pick-div .table > tbody > tr > td {
    vertical-align: middle;
}

.top-pick-div table.table {
    display: block;
    max-height: 400px;
    overflow-y: auto;
}

.red-tick {
    color: #ff2d41;
}

.gray-tick {
    color: gray;
}

.top-pick-div tr {
    border-left: 1px solid;
    border-bottom: 1px solid #676767;
}

.top-pick-div {
    border: 1px solid #676767;
    border-radius: 10px;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 0;
}

    .top-pick-div h3 {
        color: #fff;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .top-pick-div ::-webkit-scrollbar-track {
        border: 2px solid #676767;
        background-color: #000;
    }

    .top-pick-div ::-webkit-scrollbar {
        width: 10px;
        background-color: #000;
    }

    .top-pick-div ::-webkit-scrollbar-thumb {
        background-color: #676767;
        border: 2px solid #676767;
    }


.orangeInfo {
    color: orange;
    padding-bottom: 2px;
}     

.orangeLabel {
    color: orange;
    padding-left: 4px;
}     
    

div#content {
    padding-bottom: 50px;
}

.footer .container a:not(:last-child):after {
    content: "| ";
    padding: 0 10px;
}


/* My-property-css-start */

.green-value {
    color: #01a65a;
}


.red-value {
    color: #cf001f;
}

.white-text {
    color: #ffffff !important;
}

.green-text {
    color: #01a65a !important;
}

.my-property-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-label {
    width: 100% !important;
}


.add-btn {
    background-color: #1370df;
    color: white !important;
    padding: 8px 20px;
    height: auto;
    border-radius: 4px;
    font-weight: 700;
}

.compare-btn {
    background-color: #01a65a;
    color: #000 !important;
    font-weight: 700;
    float:left;
}


/* toggle-switch start */

.switch {
    display: inline-block;
    height: 28px;
    position: relative;
    width: 40px;
}

    .switch input {
        display: none;
    }

    .switch .slider {
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.4s;
        background-color: #000;
        border: 5px solid #fff;
    }

        .switch .slider:before {
            background-color: #000;
            bottom: -2px;
            content: "";
            height: 22px;
            left: -1px;
            position: absolute;
            transition: 0.4s;
            width: 22px;
            border: 6px solid #fff;
        }

.main-toggle {
    display: flex;
    align-items: center;
}

.toggle-div {
    margin-bottom: 20px;
}

.main-toggle span {
    color: white;
    font-weight: 700;
    font-size: 19px;
    margin-left: 10px;
}

label.switch {
    margin-bottom: 0;
}

.switch input:checked + .slider {
    background-color: #000;
    border: 5px solid #fff;
}

    .switch input:checked + .slider:before {
        transform: translateX(26px);
        left: -16px;
    }

.switch .slider.round {
    border-radius: 34px;
}

    .switch .slider.round:before {
        border-radius: 50%;
    }

.toggle-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.properties-row {
    margin-bottom: 25px;
    position: relative;
}


/* toggle-switch End */


/* grid style Start */

.top-row-list-properties.grid-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

    .top-row-list-properties.grid-view .properties-top-col {
        border-right: 0;
    }

    .top-row-list-properties.grid-view .row.properties-row .properties-top-col {
        min-width: 100%;
        padding-top: 0;
    }

    .top-row-list-properties.grid-view .row.properties-row .value-top-col {
        min-width: 100% !important;
        display: none;
    }

    .top-row-list-properties.grid-view .properties-row {
        max-width: 33%;
    }

.load-more-btn {
    background-color: #1370df;
    color: white !important;
    font-weight: 700;
    border-radius: 4px;
    font-size: 19px;
    margin: 0 auto;
    display: block;
}

    .load-more-btn:before {
        color: white;
        font-family: "Font Awesome 5 Free";
        content: "\f110";
        display: inline-block;
        padding-right: 6px;
        vertical-align: middle;
        font-weight: 900;
    }


/* grid style End */

.row-container > .row:first-child {
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.properties-row label.custom-control-label.custom-label > .row:first-child {
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.properties-row label.custom-control-label.custom-label p {
    margin-bottom: 0;
}

.label.row-container.custom-label p {
    margin-bottom: 0;
}

.properties-row h2.property-heading {
    color: #ffffff;
    font-weight: 700;
    font-size: 25px;
}

.properties-row p.house-address {
    font-size: 15px;
    color: white;
    max-width: 280px;
    width: 100%;
}

.properties-row hr {
    border-top: 1px solid #676767;
    max-width: 230px;
    margin-left: 0;
    margin: 8px 0;
}

.properties-top-col {
    border-right: 1px solid #676767;
    padding-top: 15px;
    padding-bottom: 15px;
}

h3.total-value {
    color: #ffffff;
    font-weight: 700;
}

.view-btn {
    background-color: #ff9362;
    color: black !important;
    padding: 5px 20px;
    border-radius: 4px;
    font-weight: 700;
}

.properties-row .custom-checkbox .custom-control-label::before {
    top: calc(50% - 15px);
    left: -2.5rem;
    border-radius: 0;
    border-colorX: #fff !important;
    border-color: #000 !important;                   /* Change when doing light view */
    background-color: #000 !important;
}

.properties-row .custom-checkbox .custom-control-labelCOMMENTED_OUT::after {
    top: calc(50% - 15px);
    left: -2.5rem;
}

.properties-row .custom-checkbox .custom-control-input:checked ~ .custom-control-labelCOMMENTED_OUT::after {
    top: calc(50% - 15px);
}

.properties-row .custom-control-labelCOMMENTED_OUT::after {
    left: -2.5rem;
}

.row-container::after {
    left: -2.5rem;
}

.properties-row label.custom-control-label.custom-label > .row:last-child {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border: 2px solid #676767;
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.row-container.custom-label > .row:last-child {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border: 2px solid #676767;
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}


.value-top-col > .row:first-child {
    border-bottom: 1px solid #676767;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.properties-row .fa-check-square {
    font-size: 25px;
    margin-right: 5px;
}

.rate-heading {
    color: white;
    font-size: 16px;
    font-weight: 500;
}


.properties-row label.custom-control-label.custom-label > .row:last-child .col-md-12 {
    display: flex;
    justify-content: space-between;
}

label.row-container.custom-label > .row:last-child .col-md-12 {
    display: flex;
    justify-content: space-between;
}

.value-top-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 15px;
    padding-bottom: 15px;
}

.properties-top-col > .row {
    display: flex;
    flex-direction: inherit;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

.value-top-col .col-md-3 {
    display: flex;
    flex-direction: column;
}

.rate-value {
    font-weight: 700;
    font-size: 20px;
    margin-top: auto;
}


/* My-property-css-end */

.active span.img-side-logo {
    /* background-color: #919198; */
    padding: 4px; /* 10px */
    border-radius: 50%;
}

.first-image {
    padding-top: 10px;
    padding-bottom: 10px;
}

.grid-view .properties-row .custom-checkbox .custom-control-labelCOMMENTED_OUT::before {
    left: -2.2rem;
}

.grid-view .properties-row .custom-checkbox .custom-control-labelCOMMENTED_OUT::after {
    left: -2.2rem;
}


/*============== new css======================= */

.top-row-list-properties.grid-view .properties-row label.custom-control-label.custom-labelCOMMENTED_OUT > .row:first-child {
    margin-top: 35px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #262626;
    border: 0;
}

.property-img {
    width: 100%;
    border-radius: 4px;
    min-height: 150px;
    z-index: 1;
}

.top-row-list-properties.grid-view .properties-row .custom-checkbox .custom-control-labelCOMMENTED_OUT::before {
    left: 50% !important;
    border-radius: 0;
    border-color: #fff !important;
    background-color: #000 !important;
    top: 0;
}

.top-row-list-properties.grid-view .properties-row .custom-checkbox .custom-control-labelCOMMENTED_OUT::after {
    left: 50%;
    top: calc(0% - 0px);
}

.row-container::after {
    left: 50%;
    top: calc(0% - 0px);
}

.top-row-list-properties.grid-view .properties-top-col > .row {
    flex-direction: column;
    align-items: flex-start;
}

    .top-row-list-properties.grid-view .properties-top-col > .row .col-md-4 {
        max-width: 100%;
        padding: 0;
        margin-bottom: 10px;
    }

.top-row-list-properties.grid-view .property-img {
    border-radius: 0;
}

.top-row-list-properties.grid-view .properties-top-col > .row .col-md-8 {
    max-width: 100%;
    background-color: #262626;
}

.top-row-list-properties.grid-view .properties-row h2.property-heading {
    font-size: 20px;
}

.top-row-list-properties.grid-view .properties-row label.custom-control-label.custom-label p {
    font-size: 18px;
    max-width: 230px;
}

.top-row-list-properties.grid-view .properties-row hr {
    max-width: 190px;
}

.top-row-list-properties.grid-view .properties-row label.custom-control-label.custom-label > .row:last-child {
    display: none;
}

.grid-price {
    display: none;
}

.top-row-list-properties.grid-view .grid-price {
    color: #fff;
    font-weight: 700;
    position: absolute;
    bottom: -8px;
    left: 0px;
    padding: 5px 10px;
    background-color: #01532dad;
    display: block;
}


/*****wk css  property search********/

input.search-bar-property {
    border: 1px solid #707070;
    width: 100%;
    height: 50px;
    padding: 0 16px;
    background-color: unset;
    border-radius: 10px;
}

    input.search-bar-property::placeholder {
        font-size: 20px;
        font-weight: bold;
        font-style: italic;
    }

button.search-icon {
    width: 70px;
    height: 47px;
    border: none;
    border-radius: 10px;
    background-color: #0F996D;
}

.search-bar-main {
    margin-top: 15px;
}

.search-by-sec p {
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
}

ul.search-by-list {
    list-style: none;
    padding: 0;
}

    ul.search-by-list li {
        display: inline-block;
        padding: 0 4px;
    }

.search-by-sec {
    margin-top: 20px;
}

ul.search-by-list li input[type="radio"] {
    margin-left: 10px;
}

ul.search-by-list li label {
    color: white;
}

ul.search-by-list li input[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -3px;
    left: -3.5px;
    position: relative;
    /* background-color: #d1d3d1; */
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

ul.search-by-list li input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #0F996D;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.property-content h3 {
    font-size: 26px;
    font-weight: bold;
    color: #8E27D2;
}

.property-content h4 {
    color: #78A481;
    font-weight: bold;
    font-size: 19px;
}

.property-detail .properties-row .fa-check-square {
    font-size: 17px;
    margin-right: 5px;
}

.property-content p {
    width: 85%;
    font-size: 15px;
    color: white;
}

.my-alerts-content .table thead {
    background-color: #0F996D;
    color: white;
}

    .my-alerts-content .table thead tr th {
        font-size: 14px;
        border: none;
    }

        .my-alerts-content .table thead tr th i {
            margin-right: 7px;
            font-size: 18px;
        }

.my-alerts-content .table tbody i {
    color: red;
    margin-right: 7px;
    font-size: 18px;
}

.my-alerts-content .table tbody tr th {
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #707070;
}

.my-alerts-content .table tbody tr td {
    color: white;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #707070;
}

    .my-alerts-content .table tbody tr td a {
        text-decoration: underline;
    }

span.pink {
    width: 20px;
    height: 20px;
    background-color: #FF0045;
    display: block;
    border-radius: 50%;
    margin-left: 14px;
}

span.red {
    width: 20px;
    height: 20px;
    background-color:red;
    display: block;
    border-radius: 50%;
    margin-left: 14px;
}

span.blue {
    width: 20px;
    height: 20px;
    background-color: #0921FF;
    display: block;
    border-radius: 50%;
    margin-left: 14px;
}

span.green {
    width: 20px;
    height: 20px;
    background-color: #77FF00;
    display: block;
    border-radius: 50%;
    margin-left: 14px;
}

section.my-alerts-content {
    margin-top: 10px;
}

.modal-width {
    max-width: 60%;
}

    .modal-width .modal-header {
        border: none;
        padding: 0;
    }

.accordion-one {
    background-color: white;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    margin-top: 20px;
    border-radius: 5px;
}

.accordion-two {
    background-color: white;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    margin-top: 20px;
    border-radius: 5px;
}

/* .active, .accordion:hover {
  background-color: #ccc; 
} */

.panel-one {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    border: 1px solid #d1d3e2;
    color: #6e707e;
}

.modal-width .modal-content {
    background-color: #2C2C2C;
    max-height: 1300px;
}

.modal-width .card {
    background-color: #2C2C2C;
    border: none;
}

.modal-width .card-header {
    background-color: #2C2C2C;
    border: none;
}

.modal-width ul#myTab {
    border: none;
}

.modal-width .modal-body {
    top: 0px;
}

.modal-width .nav-link.active {
    color: #FFFFFF;
    background-color: #01A65A;
    border-color: unset;
    border: none;
    font-size: 18px;
    font-weight: bold;
}

.modal-width .nav-tabs .nav-link {
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.modal-width ul#myTab .nav-item {
    background-color: #989898;
}

.modal-width label {
    color: white;
    font-weight: bold;
    font-size: 17px;
}

.modal-width button.btn.btn-primary {
    width: 100%;
    padding: 18px;
    margin-top: 20px;
    background-color: #1370DF;
}

.nav-link.active {
    background-color: #01A65A !important;
    font-size: 18px !important;
    padding: 9px 16px;
}

.modal-width .nav-tabs .nav-link:first-child {
    border-top-right-radius: 0;
}

a#second-tab {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.form-group i.fas.fa-map-marker-alt {
    position: absolute;
    right: 17px;
    top: 119px;
    color: white;
}

.form-group i.far.fa-calendar-alt {
    position: absolute;
    right: 19px;
    top: 12px;
    color: white;
}

.form-group i.far.fa-calendar-alt2 {
    position: absolute;
    right: 19px;
    top: 12px;
    color: white;
}

.form-group i.fas.fa-caret-down {
    position: absolute;
    right: 19px;
    top: 12px;
    color: white;
}


.modal-my-alert .card-header h1 {
    font-weight: bold;
    color: white;
}

.modal-my-alert .card-body {
    padding: 1.25rem 0 0 0;
}

.modal-my-alert .card-header {
    padding: 0 0;
}

.modal-my-alert .modal-body {
    padding: 1rem 1rem 0 1rem;
}

.modal-my-alert .modal-footer {
    border: none;
}

    .modal-my-alert .modal-footer button {
        font-size: 18px;
        text-transform: uppercase;
        background-color: #1370DF;
    }

.modal-my-alert .modal-body {
    top: 0px;
}

.modal-my-alert .modal-header {
    padding: 0;
}

.modal-width span i.fas.fa-times-circle {
    color: white !important;
}

.modal-width .close {
    padding: 7px 3px;
    margin: -1rem -1rem -1rem auto;
    opacity: 1;
}

.modal-my-alert.modal-dialog {
    position: relative;
    width: auto;
    margin: 5.5rem auto !important;
    pointer-events: none;
}

.modal-my-alert .form-group input#name {
    background: unset;
    border: 1px solid #FFFFFF;
}

    .modal-my-alert .form-group input#name::placeholder {
        color: white;
    }

.modal-my-alert .form-select {
    border: 1px solid white;
    background: unset;
}

.modal-my-alert .form-group input#pwd {
    background: unset;
    border: 1px solid white;
}

    .modal-my-alert .form-group input#pwd::placeholder {
        color: white;
    }

.accordion-one i.fas.fa-plus-circle {
    float: right;
    font-size: 22px;
}

.accordion-two i.fas.fa-plus-circle {
    float: right;
    font-size: 22px;
}

select#status:first-child {
    color: white;
}

select#trigger-criteria:first-child {
    color: white;
}

select#purchase-price:first-child {
    color: white;
}

.modal-my-alert .form-select option {
    background-color: #2C2C2C;
}

.sidebar .nav-item a.nav-link {
    display: flex !important;
    align-items: flex-end;
}


/* =================Compare modal  css start======================== */

.compare-modal .modal-header h5 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
}

.compare-modal .modal-content {
    background-color: #2C2C2C;
    border: none;
}

.compare-modal .modal-header {
    border: none;
}

.compare-modal .modal-footer {
    border: none;
}

.compare-modal .modal-dialog {
    max-width: 80%;
}

.compare-table h2 {
    font-size: 12px !important;
    color: white;
    font-weight: bold;
}

.compare-table p {
    font-size: 10px;
    margin-bottom: 0;
    color: white;
    font-weight: 400;
}

.compare-table .greenish-tick {
    font-size: 10px;
}

.compare-table .red-tick {
    font-size: 10px;
}

.compare-table thead tr th {
    /* padding: 0; */
    font-size: 13px;
    text-align: center;
    color: white;
    vertical-align: middle;
    border: 2px solid #676767;
    font-weight: 400;
}

.compare-table tbody tr td {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border: 2px solid #676767;
    padding: 35px 15px;
}

.compare-table tbody tr th {
    border: 2px solid #676767;
}

.compare-table .table thead th {
    border: 2px solid #676767;
}

.compare-table .price {
    font-size: 15px;
    padding: 5px 0px;
}

.compare-table {
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
}

    .compare-table .green-fill {
        background-color: #01A65A;
    }

    .compare-table .red-fill {
        background-color: #db071c;
    }

    .compare-table .modal-footer {
        margin-bottom: 50px !important;
    }


/* =================Compare modal css end======================== */


/* =================add modal  css start======================== */

.add-modal .modal-header h5 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
}

.add-modal .modal-content {
    background-color: #2C2C2C;
    border: none;
}

.add-modal .modal-header {
    border: none;
}

.add-modal .modal-footer {
    border: none;
}

.add-modal .modal-dialog {
    max-width: 60%;
}

.add-table h2 {
    font-size: 12px !important;
    color: white;
    font-weight: bold;
}

.add-table p {
    font-size: 10px;
    margin-bottom: 0;
    color: white;
    font-weight: 400;
}

.add-table .greenish-tick {
    font-size: 10px;
}

.add-table .red-tick {
    font-size: 10px;
}

.add-table thead tr th {
    /* padding: 0; */
    font-size: 13px;
    text-align: center;
    color: white;
    vertical-align: middle;
    border: 2px solid #676767;
    font-weight: 400;
}

.add-table tbody tr td {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: white;
    border: 2px solid #676767;
    padding: 35px 15px;
}

.add-table tbody tr th {
    border: 2px solid #676767;
}

.add-table .table thead th {
    border: 2px solid #676767;
}

.add-table .price {
    font-size: 15px;
    padding: 5px 0px;
}

.add-table {
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
}

    .add-table .green-fill {
        background-color: #01A65A;
    }

    .add-table .red-fill {
        background-color: #FF2D41;
    }

    .add-table .modal-footer {
        margin-bottom: 50px !important;
    }


/* =================add modal css end======================== */

/* =================alert modal  css start======================== */

.alert-modal .modal-header h5 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
}

.alert-modal .modal-content {
    background-color: #2C2C2C;
    border: none;
}

.alert-modal .modal-header {
    border: none;
}

.alert-modal .modal-footer {
    border: none;
}

.alert-modal .modal-dialog {
    max-width: 60%;
}

/* =================alert modal  css end======================== */

span.circle {
    height: 25px;
    width: 25px;
    background-color: white;
    display: inline-block;
    border-radius: 15px;
    position: relative;
    top: 3px;
}

.appearance-heading h3 {
    font-size: 15px;
    margin-top: 25px;
    color: white;
}

.general-heading h2 {
    color: white;
    font-size: 26px;
}

.setting-section-one ul.search-by-list li:last-child {
    margin-left: 30px;
}

.setting-section-one ul.search-by-list li label {
    margin-left: 12px;
}

.setting-section-one ul.search-by-list li {
    padding: 0;
}

.preferred-heading h2 {
    font-size: 16px;
    color: white;
    margin: 30px 0;
}

.setting-form label {
    color: white;
    font-size: 17px;
    font-weightX: bold;
    font-familyX: 'Montserrat';
}

.setting-form input#name {
    background-color: transparent;
    border: 1px solid white;
}

.indicators-section .panel-default > .panel-heading {
    color: #333;
    background-color: #fff;
    border-color: #e4e5e7;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 5px;
}

    .indicators-section .panel-default > .panel-heading a {
        display: block;
        padding: 10px 15px;
        color: black;
        text-decoration: none;
        font-size: 18px;
        font-family: 'Montserrat';
    }

        .indicators-section .panel-default > .panel-heading a:after {
            content: "";
            position: relative;
            top: 1px;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            float: right;
            transition: transform .25s linear;
            -webkit-transition: -webkit-transform .25s linear;
        }

        .indicators-section .panel-default > .panel-heading a[aria-expanded="true"] {
            background-color: transparent;
        }

            .indicators-section .panel-default > .panel-heading a[aria-expanded="true"]:after {
                content: "\2212";
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }

        .indicators-section .panel-default > .panel-heading a[aria-expanded="false"]:after {
            content: "\002b";
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
        }

.indicators-heading {
    margin: 40px 0;
}

.indicators-section .panel-body {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 8px;
    padding: 20px;
}

select#indicatior-slect {
    border: 1px solid black;
}

.accordion-select input {
    border: 1px solid black;
}

.indicators-section .panel.panel-default {
    margin-bottom: 30px;
}

.my-properties-new .value-top-col > .row:first-child {
    padding: 0;
    border: none;
}

.green-color-bg {
    background-color: #06D536;
    padding-bottom: 8px;
    height: 92px;
}

.green-new-color-bg {
    background-color: #01A65A;
    height: 92px;
}

.green-color-bg p {
    margin-bottom: 0;
}

.my-properties-new .rate-value {
    font-weight: 500;
    font-size: 19px;
    margin-top: auto;
    color: white;
}

.red-color-bg {
    background-color: #FF2D41;
    height: 92px;
}

    .red-color-bg p {
        margin-bottom: 0;
    }

.my-properties-new .properties-top-col {
    border: none;
}

.percent-rule h2 {
    text-align: center;
    font-size: 33px;
    font-weight: bold;
    color: white;
    margin-top: 40px;
}

.percent-rule-heading {
    text-align: center;
}

    .percent-rule-heading span {
        color: #06D536;
        font-size: 28px;
        font-weight: bold;
    }

.percent-rule-content {
    margin-top: 40px;
    border-bottom: 1px solid;
}

    .percent-rule-content h4 {
        color: white;
        font-size: 21px;
    }

    .percent-rule-content span.green-circle {
        width: 20px;
        height: 20px;
        background-color: #06D536;
        display: block;
        position: relative;
        border-radius: 10px;
        margin-right: 10px;
    }

    .percent-rule-content h5 {
        display: inline-flex;
        color: white;
        font-size: 18px;
    }

    .percent-rule-content span.red-circle {
        width: 20px;
        height: 20px;
        background-color: #FF2D41;
        display: block;
        position: relative;
        border-radius: 10px;
        margin-right: 10px;
    }

.percent-rule {
    border: 1px solid #676767;
    padding: 40px;
    border-radius: 11px;
}

.percent-rule-para {
    margin-top: 30px;
}

    .percent-rule-para p {
        color: white;
    }

.accordion-percent-rule .panel-default > .panel-heading {
    color: #333;
    background-color: #fff;
    border-color: #e4e5e7;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .accordion-percent-rule .panel-default > .panel-heading a {
        display: block;
        padding: 10px 15px;
        color: black;
        font-size: 17px;
        text-decoration: none;
    }

        .accordion-percent-rule .panel-default > .panel-heading a:after {
            content: "";
            position: relative;
            top: 1px;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            float: right;
            transition: transform .25s linear;
            -webkit-transition: -webkit-transform .25s linear;
        }

        .accordion-percent-rule .panel-default > .panel-heading a[aria-expanded="true"] {
            background-color: white;
            color: #1370DF;
        }

            .accordion-percent-rule .panel-default > .panel-heading a[aria-expanded="true"]:after {
                content: "\2212";
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }

        .accordion-percent-rule .panel-default > .panel-heading a[aria-expanded="false"]:after {
            content: "\002b";
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
        }

.accordion-percent-rule-para {
    background-color: white;
    padding: 17px;
}

.accordion-percent-rule div#headingOne h4.panel-title {
    margin: 0;
}

.accordion-percent-rule h4.panel-title {
    margin-top: 30px;
    margin-bottom: 0;
}

.accordion-percent-rule-para p {
    color: #919198;
    width: 380px;
    font-size: 15px;
    padding: 10px 0;
}

.accordion-percent-rule-para h5 {
    color: #1370DF;
}

.accordion-percent-rule-para p.para-one {
    font-weight: bold;
}

.calculation tbody tr td {
    font-size: 14px;
    border: none
}

hr.upon {
    margin: 5px 0;
    border-top: 1px solid #1370DF;
}

p.para-calculation {
    width: 337px;
    font-size: 16px;
}

.calculation h4 {
    text-align: center;
    font-size: 20px;
    color: black;
    font-weight: 600;
    font-family: 'Open Sans';
}

.price-370 {
    font-size: 25px;
    color: white;
    font-family: 'Open Sans';
    font-weight: bold;
    /* margin-left: 23px; */
    margin-top: 15px;
    border-top: 1px solid #676767;
    border-bottom: 1px solid #676767;
}

div#chartContainer {
    margin: 40px 29px;
}

div#chartContainer2 {
    margin: 40px 29px;
}

.chart-container {
    height: 286px;
    width: 35%;
}

/*============== new css End======================= */
/* Media Queries */
@media (max-width: 1240px) {
    .cards-row h3 {
        font-size: 42px;
    }

    .cards-row h4 {
        font-size: 22px;
    }

    .region-group {
        max-width: 285px;
        margin-left: auto;
    }

    .rate-heading {
        font-size: 13px;
    }

    .rate-value {
        font-size: 22px;
    }

    .properties-row p.house-address {
        font-size: 19px;
    }

    .properties-row .fa-check-square {
        font-size: 20px;
    }

    .grid-view .value-top-col .col-md-3 {
        min-width: 50%;
    }

    .my-alerts-content .table thead tr th {
        font-size: 11px;
        padding-right: 0;
    }

    .my-alerts-content .table tbody tr td {
        font-size: 11px;
    }

    .my-alerts-content .table tbody tr th {
        font-size: 11px;
    }

    button.search-icon {
        width: 50px;
    }
}

@media (max-width: 991.5px) {
    .sidebar #sidebarToggle {
        width: 1.5rem;
        height: 1.5rem;
    }

    button#sidebarToggle {
        right: -20px;
    }

    .card-price {
        margin-bottom: 15px;
        height: auto;
        min-height: auto;
        max-width: 390px;
        margin: 10px auto;
    }

    .top-pick-div {
        margin-bottom: 30px;
    }

    .footer .container a:not(:last-child):after {
        padding: 0 2px;
    }

    .footer .container a {
        font-size: 12px;
    }

    .rate-heading {
        font-size: 16px;
    }

    .properties-top-col {
        border-right: 0;
    }

    .grid-view .value-top-col .col-md-3 {
        min-width: 100%;
    }

    .grid-view .properties-row label.custom-control-label.custom-label > .row:last-child .col-md-12 {
        flex-direction: column;
    }

    .grid-view .properties-row label.custom-control-label.custom-label > .row:last-child .col-md-12 h3 {
        text-align: center;
    }

    label.row-container.custom-label > .row:last-child .col-md-12 {
        flex-direction: column;
    }

    label.row-container.custom-label > .row:last-child .col-md-12 h3 {
        text-align: center;
    }

    .grid-view .properties-row h2.property-heading {
        font-size: 25px;
    }

    .my-property-heading .dashboard-heading {
        font-size: 24px;
        margin-bottom: 0;
    }

    .my-property-heading {
        margin-bottom: 10px;
    }

    .my-alerts-content .table thead tr th {
        font-size: 10px;
        padding-right: 0;
        vertical-align: middle;
    }

    .my-alerts-content .table tbody tr td {
        font-size: 10px;
    }

    button.search-icon {
        width: 35px;
    }
}

@media (max-width: 767.98px) {
    .sidebar .nav-item .nav-link {
        width: 100%;
    }

    .sidebar .nav-item {
        display: flex;
    }

        .sidebar .nav-item .nav-link span {
            text-align: left;
            margin-left: 10px;
            font-size: 15px;
        }

        .sidebar .nav-item .nav-link div {
            text-align: left;
            margin-left: 10px;
            font-size: 15px;
        }

    .sidebar {
        width: 4rem;
    }

        .sidebar .nav-item .nav-link span {
            display:none;
            
        }

        .sidebar .nav-item .nav-link div {
            display: none;
        }

    li.nav-item.search-nav-item {
        display: none;
    }

    .sidebar .nav-item a.nav-link {
        display: flex !important;
        align-items: flex-start;
        justify-content: center;
    }

    .sidebar-toggled img.dashboard-logo {
        height: auto;
        box-shadow: none;
        position: relative;
        max-height: 80px;
        left: 0;
    }

    img.dashboard-logo {
        height: auto;
        position: relative;
        max-height: 80px;
        left: 0;
    }

    .sidebar .nav-item {
        padding-left: 0;
    }

        .sidebar .nav-item .collapse {
            left: calc(3.5rem + 1.5rem / 2);
        }

    div#content .container {
        max-width: 90% !important;
        padding: 0;
    }

    div#collapseTwo {
        background-color: #919198;
        top: 0px;
    }

    .sidebar .nav-item .collapse .collapse-inner {
        box-shadow: none !important;
        margin-bottom: 0;
    }

    .sidebar .nav-item .collapse {
        left: calc(3.5rem + 0.5rem / 2);
    }

    .bg-white.py-2.collapse-inner.rounded {
        padding-bottom: 6px !important;
    }

    a.collapse-item {
        padding-left: 10px !important;
    }

    span.person-name {
        padding-right: 10px;
    }

    .topbar.navbar-light .navbar-nav .nav-item .nav-link {
        padding-left: 7px;
    }

    .region-group {
        max-width: 235px;
    }

    .properties-row h2.property-heading {
        text-align: center;
    }

    p.house-address {
        text-align: center;
        max-width: 100% !important;
    }

    .properties-row hr {
        margin: 10px auto;
    }

    .indicators {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .row.properties-row {
        max-width: 450px;
        margin: 10px auto;
    }

    .properties-row h2.property-heading {
        margin-top: 10px;
    }

    .properties-row label.custom-control-labelCOMMENTED_OUT.custom-label > .row:last-child .col-md-12 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    label.row-container.custom-label > .row:last-child .col-md-12 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    span.img-side-logo {
        display: block !important;
        margin-left: 0 !important;
    }

    li.nav-item.mydropdown.active.nav-item-dropdown.nav-item-dropdown .collapse.show:before {
        display: none;
    }

    div#collapseTwo {
        border-radius: 0;
    }


    div#collapseTwo {
        min-width: 100px !important;
    }

    .property-detail .row.properties-row {
        max-width: 100%;
        margin: 0;
    }

    .property-detail .indicators {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
    }
}

@media (max-width: 576px) {
    .topbar #sidebarToggleTop {
        padding: 0 !important;
        height: 2.5rem;
        width: auto;
        display:none;   /* This hides the hamburger button on mobile. I decided to hide since it is confusing and doesn't work consistently  */
    }

    span.person-name {
        font-size: 11px;
    }

    span.person-img-user {
        font-size: 26px;
        color:#FFFFFF;
    }

    button#sidebarToggleTop:focus {
        box-shadow: none;
    }

    .view-btn {
        width: 100%;
    }

    .load-more-btn {
        width: 100%;
    }

    .grid-view .properties-row h2.property-heading {
        font-size: 20px;
    }

    .grid-view .properties-row p.house-address {
        font-size: 16px;
    }

    .top-row-list-properties.grid-view .properties-row {
        max-width: 100%;
    }

    button.search-icon {
        width: 38px;
        margin-top: 0;
    }
}

@media (max-width: 480px) {

    {/*}
    img.dashboard-logo {
        max-height: 45px !important;
    }
    */}

    .sidebar .nav-item .nav-link .img-profile,
    .topbar .nav-item .nav-link .img-profile {
        height: 40px;
        width: 40px;
    }

    i.fas.fa-bell.fa-fw {
        font-size: 16px;
    }

    button#sidebarToggleTop {
        margin-right: 0 !important;
    }

    li.nav-item.dropdown.no-arrow {
        position: relative;
        right: -20px;
    }

    .topbar {
        min-height: 55px;
        max-height: 55px;
    }

    .sidebar-toggled img.dashboard-logo {
        top: 4px;
    }

    .dash-logo-link {
        min-height: 55px;
        max-height: 55px;
    }

    .dashboard-logo {
        top: 5px;
    }

    li.nav-item.dropdown .dropdown-item {
        padding: 1rem 5px;
    }

    .region-group select#exampleFormControlSelect1 {
        padding: 0;
        font-size: 12px;
    }

    .region-group select#profileSelect {
        padding: 0;
        font-size: 12px;
    }

    .region-group select#investmentTypeSelect {
        padding: 0;
        font-size: 12px;
    }

    .region-group select#exampleFormControlSelect1 {
        background-color: #2b2b2d;
        border: 0;
        color: #c7c7c8;;
    }

    .region-group select#profileSelect {
        background-color: #2b2b2d;
        border: 0;
        color: #c7c7c8;
    }

    .region-group select#investmentTypeSelect {
        background-color: #2b2b2d;
        border: 0;
        color: #c7c7c8;
    }

    ul.navbar-nav.ml-auto {
        margin-right: 18px;
    }

    i.fa.fa-bars {
        padding-right: 4px;
    }

    .footer .container a {
        font-size: 16px;
        display: block;
        width: 100%;
        margin-bottom: 8px;
    }

    .footer-links {
        display: block;
        text-align: left;
        width: 100%;
    }

    .footer .container a:not(:last-child):after {
        content: "";
    }

    .footer .container {
        display: block;
        padding-left: 25px;
    }

    .main-toggle span {
        font-size: 16px;
    }

    .my-property-heading .dashboard-heading {
        font-size: 21px;
        margin-bottom: 0;
    }

    .add-btn {
        padding: 5px 20px;
    }

    .properties-row h2.property-heading {
        font-size: 22px;
    }

    button.search-icon {
        width: 100%;
        margin-top: 10px;
    }

    .seach-icons {
        text-align: center;
    }

    .search-by-sec {
        margin-top: 20px;
        text-align: center;
    }

        .search-by-sec p {
            font-size: 15px;
        }

    .property-content {
        text-align: center;
    }

        .property-content p {
            width: 100%;
            margin-top: 10px;
        }

    .modal-width {
        max-width: 100%;
    }

        .modal-width .close {
            padding: 7px 18px;
        }
}

.properties-modal {
    background-color: red;
    width: 90vw;   
    max-width: 90vw;
} 

.alert-edit-modal {
    background-color: #2C2C2C;
    border: none;
}

.alert-edit-modal-component { 
    background-color: #2C2C2C;
    color:#FFFFFF;
}

.setting-edit-component {
    background-color: #000000;
    color:#FFFFFF;
}

.filter-input-component { 
    background-color: #000000;
    color:#ababab;
    border-color: #626262;
}


.filter-input-icons i {
    position: absolute;
}
  
.filter-input-icons {
    width: 100%;
    margin-bottom: 10px;
}
  
.filter-icon {
    padding: 10px;
    min-width: 40px;
}
  




/* BEGIN - Indicator Info classes */

.indicator-info-modal {
    background-color: #FFFFFF;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid #1c7cfb;
}

.indicator-info-component {
    background-color: #2C2C2C;
    color:#FFFFFF;
}

.indicator-info-property-name {
    font-size:19px !important;
    font-weight:bold !important;
}

.indicator-info-property-address {
    font-size:16px !important;
}

.indicator-info-indicator-name {
    text-align:center !important;
    font-size:21px !important;
    font-weight:800 !important;
    color:#000000;
}

.indicator-info-indicator-value {
    text-align:center !important;
    font-size:48px !important;
    font-weight:700 !important;
    color: #1c7cfb;
}

.indicator-info-green-icon
{
    background-image: url("../img/indicators/traffic-lights-green.png") !important;
    background-repeat: no-repeat !important;
    height: 110px !important;
    width: 100px !important;
}

.indicator-info-red-icon
{
    background-image: url("../img/indicators/traffic-lights-red.png") !important;
    background-repeat: no-repeat !important;
    height: 110px !important;
    width: 100px !important;
}

.indicator-info-indicator-benchmark {
    font-size:17px !important;
    font-weight:700 !important;
    color: #666666;
    margin-left:5px;
}

.indicator-info-indicator-settings {
    border-top: 2px solid  #1c7cfb;
    border-bottom: 2px solid  #1c7cfb;
    margin-left: 15px;
    padding-top:3px;
    padding-bottom:1px;
}

.indicator-info-little-icon {
    height: 22px !important;
}

/* END - Indicator Info classes */

.space-bottom
{
    padding-bottom: 10px;
}

.white-letters {
    color:#FFFFFF;
}

.indicator-info-indicator-step {
    color: #1c7cfb !important;
    font-weight: 700 !important;
    font-size: 15px !important;
}

.indicator-info-indicator-steps {
    margin-left: 15px !important;
    color:#000000 !important;
    font-size:16px !important;
    width:100% !important;
}


/*************************************
   CIRCULAR BUTTON
**************************************/
.btn-circle.btn-tiny {
    width: 20px;
    height: 20px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}
.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 8px;
    text-align: center;
}
.btn-circle.btn-md {
    width: 50px;
    height: 50px;
    padding: 7px 10px;
    border-radius: 25px;
    font-size: 10px;
    text-align: center;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 12px;
    text-align: center;
}




  .zipcodeButton{
      margin-left:8px;
      margin-right:3px;
  }

  .indicatorCircleButton{
    margin-left:3px;
    margin-right:3pt;
}

  .noWrapInput {
    float: left;
  }

  .noWrap
{
    display: inline-block;
    white-space: nowrap;
}



/********************************************
  OVERRIDING BOOTSTRAP BOTTON STYLES
*********************************************/


/**************************
  DEFAULT BOOTSTRAP STYLES
**************************/

    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 10px 16px;
        text-transform: unset !important;
      }
        
      .btn-lg {
        font-size: 18px;
        line-height: 1.33;
        border-radius: 6px;
      }
      
      .btn-primary {
        color: #fff;
        background-color:#2C2C2C;
        border-color: #FFFFFF;
      }
      
      .btn-primary:hover,
      .btn-primary:focus,
      .btn-primary:active,
      .btn-primary.active,
      .open .dropdown-toggle.btn-primary {
        color: #fff;
        background-color: #2C2C2C;
        border-color: #FFFFFF;
      }
      
      /***********************
        OUTLINE BUTTONS
      ************************/
      
      .btn.outline {
        Xbackground: none;
        background-color: #2C2C2C;
        width: 100%;
        padding: 0.375rem 0.75rem;
        padding-left: 15px;
  
        text-align: left;
    
      }
      .btn-primary.outline {
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
      }
      .btn-primary.outline:hover, .btn-primary.outline:focus, .btn-primary.outline:active, .btn-primary.outline.active, .open > .dropdown-toggle.btn-primary {
        color: #FFFFFF;
        border-color: #FFFFFF;
        Xbackground: none;
        background-color: #2C2C2C;
      }
      .btn-primary.outline:active, .btn-primary.outline.active {
        border-color: #FFFFFF;
        color: #FFFFFF;
        box-shadow: none;
        Xbackground: none;
        background-color: #2C2C2C;
      }
      
      
    
      /***********************
        CUSTON BTN VALUES
      ************************/
      
.btn {
    border: 0 none;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none;
    color: #ffffff !important;
    background-color: #2C2C2C !important;
    border-color: #FFFFFF !important;
    box-shadow: none !important;
}

.with100
{
    width:95%;
    display: inline-block;
    white-space: nowrap;
}

.dropdownitem
{
    color: #FFFFFF !important;
    background-color: #2C2C2C !important;
}

.dropdownitem:hover, .dropdownitem:active, .dropdownitem:focus
{
    background-color: #0F81E8 !important;
}

.dropdown-menu {
    height: 170px;
    overflow-y: scroll;
  }

#alertDropdownSelect, #indicatorValueDropdownSelect, #locateByDropdownSelect, #investmentTypeDropdownSelect, #financingTypeDropdownSelect,#termDropdownSelect,#loanTypeDropdownSelect,#repaymentTypeDropdownSelect,#stateDropdownSelect,#countryDropdownSelect,#projectedYearOfSaleDropdownSelect,#creditScoreDropdownSelect,#financingPreferenceDropdownSelect, #numberBedroomsDropdownSelect, #numberBathroomsDropdownSelect, #rehabTypeDropdownSelect, #propertyTypeDropdownSelect, #minimumGreenIndicatorCountDropdownSelect, #roleDropdownSelect {
    background-color: #2C2C2C;
    width: 100%;
    border-color: #FFFFFF;
    text-align: left;
}


.customDropdownSelectWrapper{
    background-color: #2C2C2C;
    padding: 0px;
    margin: 0px;
}

#netProfitComparisonDropdownSelect,#roiComparisonDropdownSelect,#loanToValueRatioFlippingComparisonDropdownSelect,#keyRiskIndicatorComparisonDropdownSelect,#ruleOf70ComparisonDropdownSelect,#netCashFlowComparisonDropdownSelect,#loanToValueRatioRentalComparisonDropdownSelect,#breakEvenRatioComparisonDropdownSelect,#internalRateOfReturnComparisonDropdownSelect, #profitabilityIndexComparisonDropdownSelect, #cashOnCashReturnComparisonDropdownSelect, #capitalizationRateComparisonDropdownSelect, #grossRentMultiplierComparisonDropdownSelect, #creditScoreComparisonDropdownSelect, #priceToRentRatioComparisonDropdownSelect, #marketValuePercentComparisonDropdownSelect, #costOfDebtComparisonDropdownSelect, #loanToValueRatioComparisonDropdownSelect, #debtCoverageRatioComparisonDropdownSelect, #backEndRatioComparisonDropdownSelect, #frontEndRatioComparisonDropdownSelect {
    background-color: #000000;
    width: 100%;
    border-color: #FFFFFF !important;
    text-align: left;
    margin-right:12px !important;
}

#lookupStateDropdownSelect {
    width: 100%;
    border-color: #1c7cfb !important;
    text-align: left;
}

.lookupDropdownSelectWrapper{
    background-color: #ffffff;
    padding: 0px;
    margin: 0px;
}

.lookupdropdownitem
{
    color: #000000 !important;
    background-color: #ffffff !important;
}

.lookupdropdownitem:hover, .lookupdropdownitem:active, .lookupdropdownitem:focus
{
    background-color: #0F81E8 !important;
}


div .customDropdownSelectWrapper div
{
    background-color:#2C2C2C;
    width: 100%;
    border-color: #FFFFFF !important;
}


.dropdown-toggle::after {
  position: absolute;
  right: 6px;
  top: 16px;
  color: white;
}

.subsectionLabel {
    color:#FFFFFF;
    text-align: left;
    width:100%;
    color: orange;
    cursor: pointer;
}

.subsectionLabel:hover {
    color: #4e73df;
  }

.helpText {
    padding:10px;
    text-align: left;
}

.plusSign{
    font-size: 20px;
    padding-left: 5px;
}

.centeredLabel {
    color:#FFFFFF;
    text-align: center;
    width:100%;
    color: orange;
}

.dont-display {
    display:none;
}

.messagetooltip > .tooltip-inner {
    max-width: none;
  }
  

  .messagetooltipWhite > .tooltip-inner {
    max-width: none;
    background-color: #FFFFFF;
    color:#000000;
    font-weight:500;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.propertyInfo
{
    color: #FFFFFF !important;
    padding-top: 5px !important;
    padding-left: 15px !important;
    padding-bottom: 3px !important;
}

.red-indicator-tile-dash-mobile
{
    background-image: url("../img/indicators/app-tile-red-dash.png") !important;
    background-repeat: no-repeat !important;
    height: 82px !important;
    width: 88px !important;
    text-align: center !important;
    padding-top: 5px !important;
    color:#FFFFFF !important;
    font-size: 15px !important;
    font-weight: 600 !important;

}

.green-indicator-tile-dash-mobile
{
    background-image: url("../img/indicators/app-tile-green-dash.png") !important;
    background-repeat: no-repeat !important;
    height: 82px !important;
    width: 88px !important;
    text-align: center !important;
    padding-top: 5px !important;
    color:#000000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.disabled-indicator-tile-dash-mobile
{
    background-image: url("../img/indicators/app-tile-disabled-dash.png") !important;
    background-repeat: no-repeat !important;
    height: 82px !important;
    width: 88px !important;
    text-align: center !important;
    padding-top: 5px !important;
    color:#000000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.indicator-name-dash-mobile
{
    padding-top: 1px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    min-height: 40px !important;
    font-size: 13px !important;
}

.indicator-value-dash-mobile
{
    padding-top: 0px !important;
    font-size:15px !important;
    font-weight: 700 !important;
}

.property-info-dash-mobile
{
    border: 1px solid #FFFFFF;
    height: 97px !important;
    widthX: 105px !important;
    text-align: center !important;
    padding: 5px !important;
    colorX:#FFFFFF !important;
    font-size: 13px !important;
    font-weight: 400 !important;
}

.round-border-dash-mobile{
    border: 1px solid #6d6d6d;
    border-radius: 3%;
    margin-bottom: 25px !important;
    margin-top:0px !important;
    margin-left:0px !important;
    margin-right:0px !important;
    padding-top:0px !important;
    padding-left:0px !important;
    padding-right:0px !important;
    padding-bottom: 5px !important;
        width: %100 !important;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
        align-items: center !important;
}

.card2 {
    border: 1px solid #FFFFFF !important;
    border-radiusx: 1% !important;
}

/* DESKTOP CLASSES - General */
@media screen and (min-width: 780px) {

    .compound-column-width
    {
        width: 80% !important;
    }

    .repayment-type-column-width
    {
        width: 80% !important;
    }

    .property-modal-width {
        max-width: 70% !important;
    }

    .detail-modal-width {
        max-width: 543px !important;
    }

    .property-edit-modal-width {
        min-width: 60% !important;
    }

    .info-modal-width {
        max-width: 565px !important;
   }

    .property-indicator-width {
        max-width: 20% !important;
    }

    .red-indicator-tile 
    {
        background-image: url("../img/indicators/app-tile-red.png") !important;
        background-repeat: no-repeat !important;
        height: 152px !important;
        width: 165px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#FFFFFF !important;
        font-size: 19px !important;
        font-weight: 600 !important;

    }

    .green-indicator-tile
    {
        background-image: url("../img/indicators/app-tile-green.png") !important;
        background-repeat: no-repeat !important;
        height: 152px !important;
        width: 165px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#000000 !important;
        font-size: 19px !important;
        font-weight: 600 !important;
    }

    .disabled-indicator-tile
    {
        background-image: url("../img/indicators/app-tile-disabled.png") !important;
        background-repeat: no-repeat !important;
        height: 152px !important;
        width: 165px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#000000 !important;
        font-size: 19px !important;
        font-weight: 600 !important;
    }

    .indicator-name
    {
        padding-top: 10px !important;
        min-height: 70px !important;
    }

    .indicator-value
    {
        padding-top: 10px !important;
        font-size:22px !important;
        font-weight: 700 !important;
    }

    
.indicator-info-indicator-accept {
    color:#000000 !important;
    font-weight: bold !important;
}

.indicator-info-indicator-settings-creteria {
    color: #1c7cfb !important;
    padding-left: 5px;
    font-weight: 700;
    font-size:18px;
}

.indicator-info-indicator-result-text {
    color: #000000 !important;
    font-sizex:18px;
}

.indicator-info-indicator-expandable-title {
    color: #1c7cfb !important;
    font-weight: 700;
    font-size:18px;
    margin-top:10px !important;
}


.indicator-info-indicator-sub-title {
    color: #333333 !important;
    font-weight: 700;
    font-size:18px;
    margin-top:10px !important;
}


.indicator-info-calculations-table-col1
{
    min-widthX: 30% !important;
}

.indicator-info-calculations-table-col2
{
    min-widthX: 10% !important;
    text-align: center !important;
}

.indicator-info-calculations-table-col3
{
    min-widthX: 10% !important;
    text-align: right !important;
}

.indicator-info-formula-table-col1
{
    padding-top: 14px !important;
}
    .desktop-container {
        padding-left: 10px !important;
        margin-left: 10px !important;
        max-width: 97% !important;
    }

    .mobile-only-block 
    {
        display: none !important;
    }

    .desktop-only-block 
    {
        /*display:block !important;  NOTE: Removing this make its work on entire block and inline*/
    }

}


/* Desktop size specific. The rest of the stuff should be put above */
/*
  Desktop screen sizes:
    5120
    4096
    3840
    3440
    2560
    2048
    1920
    1680
    1600
    1440
    1366
    1280
    1024
    Note: Check with https://responsivedesignchecker.com/
*/
@media screen and (min-width: 780px) and (max-width : 1599px) {
    
    .red-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-red-dash.png") !important;
        background-repeat: no-repeat !important;
        height: 86px !important;
        width: 93px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#FFFFFF !important;
        font-size: 12px !important;
        font-weight: 600 !important;
    
    }
    
    .green-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-green-dash.png") !important;
        background-repeat: no-repeat !important;
        height: 86px !important;
        width: 93px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#000000 !important;
        font-size: 12px !important;
        font-weight: 600 !important;
    }
    
    .disabled-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-disabled-dash.png") !important;
        background-repeat: no-repeat !important;
        height: 86px !important;
        width: 93px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#000000 !important;
        font-size: 12px !important;
        font-weight: 600 !important;
    }

    .indicator-name-dash
    {
        padding-top: 3px !important;
        min-height: 55px !important;
    }
    
    .indicator-value-dash
    {
        padding-top: 3px !important;
        font-size:15px !important;
        font-weight: 700 !important;
    }
    
    .property-info-dash
    {
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        height: 86px !important;
        widthX: 105px !important;
        text-align: center !important;
        padding: 5px !important;
        colorX:#FFFFFF !important;
        font-size: 12px !important;
        font-weight: 300 !important;
    }

    .property-info-address-dash
    {
        font-size: 15px !important;font-weight: 100;color:#FFFFFF;
    }
    
 }

    
@media screen and (min-width: 1600px) and (max-width : 1680px) {
    
   
.red-indicator-tile-dash
{
    background-image: url("../img/indicators/app-tile-red-dash-1440.png") !important;
    background-repeat: no-repeat !important;
    height: 107px !important;
    width: 115px !important;
    text-align: center !important;
    padding: 5px !important;
    color:#FFFFFF !important;
    font-size: 15px !important;
    font-weight: 600 !important;

}

.green-indicator-tile-dash
{
    background-image: url("../img/indicators/app-tile-green-dash-1440.png") !important;
    background-repeat: no-repeat !important;
    height: 107px !important;
    width: 115px !important;
    text-align: center !important;
    padding: 5px !important;
    color:#000000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.disabled-indicator-tile-dash
{
    background-image: url("../img/indicators/app-tile-disabled-dash-1440.png") !important;
    background-repeat: no-repeat !important;
    height: 107px !important;
    width: 115px !important;
    text-align: center !important;
    padding: 5px !important;
    color:#000000 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}

.indicator-name-dash
{
    padding-top: 3px !important;
    min-height: 65px !important;
}

.indicator-value-dash
{
    padding-top: 1px !important;
    font-size:17px !important;
    font-weight: 700 !important;
}

.property-info-dash
{
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    height: 107px !important;
    widthX: 105px !important;
    text-align: center !important;
    padding: 5px !important;
    colorX:#FFFFFF !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}


.property-info-address-dash
{
    font-size: 12px !important;
}

}

@media screen and (min-width: 1681px) and (max-width: 2048px) {
      
    .red-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-red-dash-1900.png") !important;
        background-repeat: no-repeat !important;
        height: 134px !important;
        width: 145px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#FFFFFF !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    
    }
    
    .green-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-green-dash-1900.png") !important;
        background-repeat: no-repeat !important;
        height: 134px !important;
        width: 145px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#000000 !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    }
    
    .disabled-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-disabled-dash-1900.png") !important;
        background-repeat: no-repeat !important;
        height: 134px !important;
        width: 145px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#000000 !important;
        font-size: 20px !important;
        font-weight: 600 !important;
    }

    .indicator-name-dash
    {
        padding-top: 3px !important;
        min-height: 86px !important;
    }
    
    .indicator-value-dash
    {
        padding-top: 3px !important;
        font-size:21px !important;
        font-weight: 700 !important;
    }
    
    .property-info-dash
    {
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        height: 134px !important;
        widthX: 105px !important;
        text-align: center !important;
        padding: 5px !important;
        colorX:#FFFFFF !important;
        font-size: 18px !important;
        font-weight: 400 !important;
    }
    
    
    .property-info-address-dash
    {
        font-size: 12px !important;
    }
    
    
}

@media screen and (min-width: 2049px) and (max-width:3439px) {
      
    .red-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-red-dash-2050.png") !important;
        background-repeat: no-repeat !important;
        height: 185px !important;
        width: 200px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#FFFFFF !important;
        font-size: 28px !important;
        font-weight: 600 !important;
    
    }
    
    .green-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-green-dash-2050.png") !important;
        background-repeat: no-repeat !important;
        height: 185px !important;
        width: 200px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#000000 !important;
        font-size: 28px !important;
        font-weight: 600 !important;
    }
    
    .disabled-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-disabled-dash-2050.png") !important;
        background-repeat: no-repeat !important;
        height: 185px !important;
        width: 200px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#000000 !important;
        font-size: 28px !important;
        font-weight: 600 !important;
    }

    .indicator-name-dash
    {
        padding-top: 12px !important;
        min-height: 130px !important;
    }
    
    .indicator-value-dash
    {
        padding-top: 0px !important;
        font-size:35px !important;
        font-weight: 700 !important;
    }
    
    .property-info-dash
    {
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        height: 185px !important;
        widthX: 105px !important;
        text-align: center !important;
        padding: 5px !important;
        colorX:#FFFFFF !important;
        font-size: 25px !important;
        font-weight: 400 !important;
    }
    
    
    .property-info-address-dash
    {
        font-size: 20px !important;
    }
    
    
}

@media screen and (min-width: 3440px) and (max-width:4096px) {
      
    .red-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-red-dash-3440.png") !important;
        background-repeat: no-repeat !important;
        height:268px !important;
        width: 290px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#FFFFFF !important;
        font-size: 40px !important;
        font-weight: 600 !important;
    
    }
    
    .green-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-green-dash-3440.png") !important;
        background-repeat: no-repeat !important;
        height: 268px !important;
        width: 290px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#000000 !important;
        font-size: 40px !important;
        font-weight: 600 !important;
    }

    .disabled-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-disabled-dash-3440.png") !important;
        background-repeat: no-repeat !important;
        height: 268px !important;
        width: 290px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#000000 !important;
        font-size: 40px !important;
        font-weight: 600 !important;
    }
    
    .indicator-name-dash
    {
        padding-top: 20px !important;
        min-height: 160px !important;
    }
    
    .indicator-value-dash
    {
        padding-top: 0px !important;
        font-size:45px !important;
        font-weight: 700 !important;
    }
    
    .property-info-dash
    {
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        height: 268px !important;
        widthX: 105px !important;
        text-align: center !important;
        padding: 5px !important;
        colorX:#FFFFFF !important;
        font-size: 35px !important;
        font-weight: 400 !important;
    }
    
    
    .property-info-address-dash
    {
        font-size: 25px !important;
    }
    
    
}

@media screen and (min-width: 4097px) {
      
    .red-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-red-dash-5000.png") !important;
        background-repeat: no-repeat !important;
        height:417px !important;
        width: 452px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#FFFFFF !important;
        font-size: 60px !important;
        font-weight: 600 !important;
    
    }
    
    .green-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-green-dash-5000.png") !important;
        background-repeat: no-repeat !important;
        height: 417px !important;
        width: 452px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#000000 !important;
        font-size: 60px !important;
        font-weight: 600 !important;
    }

    .disabled-indicator-tile-dash
    {
        background-image: url("../img/indicators/app-tile-disabled-dash-5000.png") !important;
        background-repeat: no-repeat !important;
        height: 417px !important;
        width: 452px !important;
        text-align: center !important;
        padding: 2px !important;
        color:#000000 !important;
        font-size: 60px !important;
        font-weight: 600 !important;
    }
    
    .indicator-name-dash
    {
        padding-top: 40px !important;
        min-height: 230px !important;
    }
    
    .indicator-value-dash
    {
        padding-top: 0px !important;
        font-size:75px !important;
        font-weight: 700 !important;
    }
    
    .property-info-dash
    {
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        height: 417px !important;
        widthX: 105px !important;
        text-align: center !important;
        padding: 5px !important;
        colorX:#FFFFFF !important;
        font-size: 50px !important;
        font-weight: 400 !important;
    }
    
    
    .property-info-address-dash
    {
        font-size: 45px !important;
    }
    
    
}


/* DESKTOP CLASSES - NOT SIZE SPECIFIC */
@media only screen and (min-width: 601px) {

    /********* TOP AND LEFT NAV LOOK AND FEEL begin *********/

        img.dashboard-logo {
            height: auto;
            max-width: 130px;
            width: 100%;
        }
    
        .left-nav-borders {
            background-color:#000000;
            padding:10px;
            paddingTopX:10px;
        }
        

        a.sidebar-brand.d-flex.align-items-center.justify-content-center {
        padding: 0;
        background-color: #151718;
        /*  border-right: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important; */
        }

        .region-group span#basic-addon1 {
            /* background-color: #000000;
            border: 0;
            color: #fff;
            */
            background-color: #f4b619;
            border: 0;
            color: #000000;
            font-weight: 600;
        }

        .investment-type-dropdown-label {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-colorX: #000000 !important;
            background-colorX: #000000 !important;
            colorX: #fff !important;
        }

        .region-group select#profileSelect {
            /*
            background-color: #2b2b2d;
            border: 0;
            color: #c7c7c8;
            */
            /* background-color: #a0a0a0; */

            background-color: #bcbcbc;
            border: 0;
            color: #000000;
            font-weight: 500;
        }

        .region-group select#investmentTypeSelect {
            /*
            background-color: #2b2b2d;
            border: 0;
            color: #c7c7c8;
            */

            /* background-color: #a0a0a0; */

            background-color: #bcbcbc;
            border: 0;
            color: #000000;
            font-weight: 500;
        }

        .footer {
        /*
        background-color: #919198;
        */
        background-color: #000000;
        }

        .footer .container {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        }

        .footer .container a {
        text-decoration: none;
        /*
        color: #000;
        */
        color: #adadad;
        }

        span.person-img-user {
        font-size: 32px;
        color:#bcbcbc;
        }

    
        .sidebar li.nav-item.active {
            border-radius: 15px 15px 15px 15px !important;
            background-color: #000000;
            background-colorX: #f4b619;
            colorX:#000000 !important;
            font-weight: 100 !important;
            margin-leftX: 5px !important;
        }
        
        .sidebar li.nav-item div {
            margin-left: 15px !important;
            font-size: 17px;
            color:#FFFFFF;
        }
        
        
    /********* TOP AND LEFT NAV LOOK AND FEEL end *********/

    .trial-property-message-show {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 120px;
        left: 0;
        text-align: center;
        font-size: 20px;
        color:#c7c7c7;
        display: block;
    }
    
    .trial-property-message-dasboard-show {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 480px;
        left: 100px;
        text-align: center;
        font-size: 18px;
        color:#000000;
        display: block;
        z-index: 99;
    }

    .trial-property-message-dasboard-show-bk {
        border-radius: 25px;
        background: #FFFFFF;
        padding: 15px;
        width: 200px;
        height: 150px;
        opacity: 0.8;
     }

    .no-record-found-properties
    {
        padding-top:105px !important;
        width:100% !important;
        text-align:center !important;
        padding-left:20% !important;
        padding-right:20% !important;
        color:#888888;
        font-size: 16px;
    }

    .no-record-found-dashboard
    {
        padding-top:110px !important;
        width:100% !important;
        text-align:center !important;
        padding-left:20% !important;
        padding-right:20% !important;
        color:#888888;
        font-size: 16px;
    }

    .dashboard-heading {
        color: white;
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 25px;
    }

    .property-table-padding {
        padding-left:12px !important
    }

    #upper-right-help {
        position: relative;  
    }
    #upper-right-help div {
        position: absolute;
        top: -40px;
        right: -5px;
    }

    .help-ifo-modal .modal-header h5 {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
    }
    
    .help-ifo-modal .modal-content {
        background-color: #FFFFFF;
        border: none;
        color:#000000;
    }
    
    .help-ifo-modal .modal-header {
        border: none;
    }
    
    .help-ifo-modal .modal-footer {
        border: none;
    }
    
    .help-ifo-modal .modal-dialog {
        max-width: 50%;
    }

    .metric-chart-section
    {
        padding-bottom: 10px;
        color:#FFFFFF;
        text-align:center;
        min-height: 480px; 
    }

    .metric-budget
    {
        padding-left:12px;
        text-align: center;
    }

    .scrollable-help-text {
        height: 550px;
        overflow: auto;
        /* overflow-y: auto;*/
      }

    .footer-buttons
    {
        width:"100px" !important;
    }

    .projected-sale-proceeds-with 
    {
        min-width:270px !important;
    }

    .property-indicator-height {
        min-height: 75px !important;
    }

    .table-header-text-center {
        text-align: left;
    } 

    .setting-edit-field-td {
        width:80% !important;
    }


}



/* MOBILE CLASSES  */
@media only screen and (max-width: 600px) {
    

    /********* TOP AND LEFT NAV LOOK AND FEEL begin *********/    
    
        img.dashboard-logo {
            height: 65px !important;
            widthX: 3px !important;
        /*
        height: auto;
        max-width: 130px;
            width: 100%; */
        }
        
        a.sidebar-brand.d-flex.align-items-center.justify-content-center {
            padding: 0;

            /*
            background-color: #919198;
            border-right: 1px solid #000 !important;
            border-bottom: 1px solid #000 !important;
            */

            background-color: #151718;
        }

            .region-group span#basic-addon1 {
                padding: 5px 20px;
            }

            .region-group span#basic-addon1 {
                background-color: #000000;
                border: 0;
                color: #fff;
            }

            .region-group span#basic-addon1 {
                padding: 3px;
                font-size: 12px;
            }


            .investment-type-dropdown-label {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-color: #000000 !important;
                background-color: #000000 !important;
                color: #fff !important;
            }

            .region-group select#profileSelect {
                background-color: #2b2b2d; 
                border: 0;
                color: #c7c7c8;
                font-size: 14px !important;
            }

            .region-group select#investmentTypeSelect {
                background-color: #2b2b2d;
                border: 0;
                color: #c7c7c8;
                font-size: 14px !important;
            }
            
            .footer {
                background-color: #919198;
            }

            .footer .container {
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .footer .container a {
                text-decoration: none;
                color: #000;
            }

            .sidebar .nav-item {
                padding-left: 25px;
                /* border-bottom: 2px solid #24243e; */
                border-bottomX: 2px solid #24243e;
            }
            
            
            .sidebar li.nav-item.active img {
                filter: invert(1);
            }
            
            .sidebar li.nav-item.mydropdown.active > a::before {
                height: 107.5px;
                position: absolute;
                background-size: 100% 100%;
                right: -6.8px;
                top: -25.9px;
            }
            
            .sidebar li.nav-item.active > a::before {
                height: 112.5px;
                position: absolute;
                background-size: 100% 100%;
                right: -6.4px;
                top: -28.8px;
            }
            
            
            .sidebar li.nav-item.active {
                background-color: #000;
                color: #919198 !important;
                margin-left: 30px;
                padding-left: 5px;
                border-radius: 40px 0 0 40px !important;
                display: flex !important;
                align-items: center !important;
                padding-bottom: 9px;
            }
            
            
            .first-nav-item.active {
                padding-bottom: 0 !important;
            }
            
            .sidebar li.nav-item.active span {
                color: #919198 !important;
                margin-left: 8px !important;
                font-weight: 600;
            }
            
            .sidebar li.nav-item.active div {
                color: #919198 !important;
                margin-left: 8px !important;
                font-weight: 600;
            }
            
            .sidebar li.nav-item div {
                margin-left: 15px !important;
                font-size: 17px;
            }
            
            .sidebar li.nav-item.active a {
                padding: 6px 10px !important;
                padding-left: 7px !important;
            }
            
            .sidebar li.nav-item.active span {
                /* display: block; */
                position: relative;
            }
            
            .sidebar li.nav-item.active div {
                /* display: block; */
                position: relative;
            }
            
            .sidebar li.nav-item.active span {
                padding-top: 6px;
            }
            
            .sidebar li.nav-item.active div {
                padding-top: 6px;
            }
            
            .sidebar li.mydropdown.nav-item.active > a::before {
                content: "";
                background: url(../img/others/border.jpg);
                width: 40px;
                height: 107.5px;
                position: absolute;
                background-size: 100% 100%;
                right: -6.8px;
                top: -25.9px;
            }
            
            .sidebar li.nav-item.active > a::before {
                content: "";
                background: url(../img/others/border.jpg);
                width: 40px;
                height: 101px;
                position: absolute;
                background-size: 100% 100%;
                right: -6.4px;
                top: -24.8px;
            }
            
            
            .sidebar li.nav-item.active.nav-item-dropdown .collapse.show a {
                color: #919198 !important;
            }
            
            .sidebar li.nav-item.active.nav-item-dropdown {
                flex-direction: column;
                background: transparent;
                border: 0;
            }
            
            .sidebar li.nav-item.active.nav-item-dropdown .collapse.show {
                background: #000;
                margin: 0;
                width: 100%;
                border-radius: 50px;
            }

                .sidebar li.nav-item.active.nav-item-dropdown .collapse.show > .collapse-inner.rounded {
                    margin-bottom: 0;
                }

                .sidebar li.nav-item.active.nav-item-dropdown .collapse.show:before {
                    content: "";
                    background: url(../img/others/border.jpg);
                    width: 40px;
                    height: 94px;
                    position: absolute;
                    background-size: 100% 100%;
                    right: -6.4px;
                    top: -23px;
                }

            .sidebar li.nav-item.active.nav-item-dropdown > a::before {
                opacity: 0;
            }

            .sidebar li.nav-item.active.nav-item-dropdown span {
                color: #000 !important;
            }

            .sidebar-dark .nav-item.active .nav-link {
            align-items: flex-end;
            }

            .sidebar-dark .first-nav-item.active .nav-link {
            align-items: center;
            }

            .sidebar li.nav-item.active {
                margin-left: 4px;
            }

            .sidebar li.nav-item.active > a::before {
                display: none;
            }



            .sidebar li.nav-item.active.nav-item-dropdown .collapse.show a {
                color: #000 !important;
            }

            

    /********* TOP AND LEFT NAV LOOK AND FEEL end *********/

    .trial-property-message-show {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 480px;
        left: 0;
        text-align: center;
        font-size: 20px;
        color:#c7c7c7;
        display:block;
    }

    .trial-property-message-dasboard-show {
        width: 100%;
        height: 10%;
        position: absolute;
        top: 900px;
        left: 23px;
        text-align: center;
        font-size: 16px;
        color:#000000;
        display: block;
        z-index: 99;
    }

    .trial-property-message-dasboard-show-bk {
        border-radius: 25px;
        background: #FFFFFF;
        padding: 10px;
        width: 200px;
        height: 150px;
        opacity: 0.8;
     }


    .no-record-found-properties
    {
        padding-top:65px !important;
        width:100% !important;
        text-align:center !important;
        padding-left:20% !important;
        padding-right:20% !important;
    }

    .no-record-found-dashboard
    {
        padding-top:63px !important;
        width:100% !important;
        text-align:center !important;
        padding-left:20% !important;
        padding-right:20% !important;
    }

    .dashboard-heading {
        color: white;
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 25px;
    }

    .property-table-padding {
        padding-left:0px !important
    }

    .investment-type-label {
        float:'right' !important;
        font-size:'14px' !important;
        padding-right:'0px' !important;
        padding-top:'0px' !important;
    }

    .view-mode-padding {
        padding-bottom: 10px;
    }

    .help-ifo-modal .modal-header h5 {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
    }
    
    .help-ifo-modal .modal-content {
        background-color: #FFFFFF;
        border: none;
        color:#000000;
    }
    
    .help-ifo-modal .modal-header {
        border: none;
    }
    
    .help-ifo-modal .modal-footer {
        border: none;
    }
    
    .help-ifo-modal .modal-dialog {
        max-width: 99%;
    }

    .scrollable-help-text {
        height: 650px;
        overflow: auto;
        max-width: 350px !important;
        /* overflow-y: auto;*/
      }

      .scrollable-help-text2 {
        height: 350px !important;
        overflow: auto !important;
        /* overflow-y: auto;*/
      }

    .setting-edit-field-td {
        width:100% !important;
    }

    .table-header-text-center {
        text-align: center;
    } 
    
    .footer-buttons
    {
        width:"100%" !important;
    }

    .compound-column-width
    {
        width: 200% !important;
    }

    #mobile-check-element { display: none; }

    .mobile-only-block 
    {
       /* display: block !important; NOTE: Removing this make its work on entire block and inline*/
    }

    .desktop-only-block 
    {
        display:none !important;
    }

    .lookupButton
    {
        width:92%;
    }
    
    .red-indicator-tile
    {
        background-image: url("../img/indicators/app-tile-red.png") !important;
        background-repeat: no-repeat !important;
        width: 104px !important;
        height: 96px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#FFFFFF !important;
        font-size: 14px !important;
        font-weight: 600 !important;

    }

    .green-indicator-tile
    {
        background-image: url("../img/indicators/app-tile-green.png") !important;
        background-repeat: no-repeat !important;
        width: 104px !important;
        height: 96px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#000000 !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    .disabled-indicator-tile
    {
        background-image: url("../img/indicators/app-tile-disabled.png") !important;
        background-repeat: no-repeat !important;
        width: 104px !important;
        height: 96px !important;
        text-align: center !important;
        padding: 5px !important;
        color:#000000 !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    .indicator-name
    {
        padding-top: 5px !important;
        min-height: 30px !important;
    }

    .indicator-value
    {
        padding-top: 3px !important;
        font-size:16px !important;
        font-weight: 700 !important;
    }

    .info-modal-width {
        width:48% !important; 
        top:0%;
        left:-5%;
        min-width: 102% !important;
   }

   
.indicator-info-indicator-accept {
    color:#000000 !important;
    font-weight: bold !important;
}

.indicator-info-indicator-settings-creteria {
    color: #1c7cfb !important;
    padding-left: 5px;
    font-weight: 700;
    font-size:14px;
}

.indicator-info-indicator-result-text {
    color: #000000 !important;
    font-sizex:14px;
}

.indicator-info-indicator-expandable-title {
    color: #1c7cfb !important;
    font-weight: 700;
    font-size:16px;
    margin-top:10px !important;
}


.indicator-info-indicator-sub-title {
    color: #333333 !important;
    font-weight: 700;
    font-size:14px;
    margin-top:10px !important;
}


    .indicator-info-calculations-table
    {
        max-width: 85% !important;
    }

    .indicator-info-calculations-table-col1
    {
        min-width: 50% !important;
        max-width: 52% !important;
    }

    .indicator-info-calculations-table-col2
    {
        min-width: 10% !important;
        max-width: 12% !important;
        text-align: center !important;
    }

    .indicator-info-calculations-table-col3
    {
        min-width: 10% !important;
        max-width: 32% !important;
        text-align: right !important;
    }

    .indicator-info-formula-table
    {
        max-width: 85% !important;
    }

    .indicator-info-formula-table-col1
    {
        max-width: 50% !important;
        vertical-align: middle !important;
    }

    .indicator-info-formula-table-col2
    {
        max-width: 50% !important;
        text-align: center !important;
    }

    .indicator-info-margins
    {
        margin-left:2px !important;
        margin-right:2px !important;
    }

}

.metric-border
{
    border: 2px solid #777777;
    margin:3px;
    padding:5px;
    background-color: #2C2C2C;
}

.metric-tile
{
    padding-bottom: 10px;
    color:#FFFFFF;
    text-align:center;
    min-height: 167px;
}

.metric-indicator-value
{
    font-size: 35px;
}


.metric-chart-item
{
    height:140px;
    margin-top:10px;
    margin-left:13px;
    margin-right:13px;
    min-height: 120px;
    text-align:left;
    font-size: 13px;
    
}

.metric-graph-section
{
    margin-left:2px;
    margin-right:5px;
    margin-top:14px;
}

.green-chart-bar
{
    background-color:#1cba15;
    font-size:11px;
    text-align:center;
    max-height:17px;
    border-top:1px solid #1ba315;
    border-left:1px solid #1ba315;
    border-right:1px solid #1ba315;
}

.red-chart-bar
{
    background-color:#da000d;
    font-size:11px;
    text-align:center;
    max-height:17px;
    border-bottom:1px solid #c2000d;
    border-left:1px solid #c2000d;
    border-right:1px solid #c2000d;
}

.page-short-description {
    color:#c9c9c9;
    font-size:16px;
}

.button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #4e73df;
    text-decoration: underline;
    cursor: pointer;
}

.button-link-not-blue {
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
}

.accountLabel {
    color : #b5b5b5
}

.infoHelpbutton {
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    padding: 0 !important;
    margin: 0 !important;
}

.infoHelpbutton:focus {
    border: none;
    padding: 0;
    margin: 0;
}


.property-date
{
    color:#999999;
}

.properties-row-label       /* This class is used to put the NEW label on the rows*/
{
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.properties-row-label::before{  /* This class is used to put the NEW label on the rows*/
    content: "NEW";
    position: absolute;
    top: 0px;
    right: -27px;
    width: 95px;
    height: 40px;
    transform: rotate(45deg);
    background: darkorange;
    padding: 10px ;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    z-index: 99;
}

.blur-component{

    filter:blur(6px);
    pointer-events: none;
}

.trial-property-message-hide {
    display: none;
}

     
.demo-label {
    position:fixed;
    left:50%;
    animation: blink-animation 4s steps(5, start) infinite;
    -webkit-animation: blink-animation 4s steps(5, start) infinite;
    color:#06D536;
}
@keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }


  @media only screen and (min-width: 1501px) {
    .property-info-address-dash2
    {
        font-size: 1vw !important;font-weight: 100;color:#FFFFFF;
    }

    .block-greater-1500 {
        display: block;
    }

    .block-less-1500 {
        display: none;
    }

  }

  @media (max-width: 1500px) {
      
    .property-info-address-dash2
    {
        font-size: 0.7vw !important;font-weight: 100;color:#FFFFFF;
    }

    
    .block-greater-1500 {
        display: none;
    }

    .block-less-1500 {
        display: block;
    }
  }



[required] { background: linear-gradient(225deg, red 10px, #2C2C2C 5px); }